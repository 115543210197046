.gantt {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .gantt-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .gantt-header {
      flex: 0 0 72px;
      padding: 0 20px 0 43px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 18px;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .gantt-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 10px;

      flex: 1 0;
      overflow: hidden;
      border-color: var(--arxs-nav-border-color);

      .b-container {
        height: 100%;
        
      }

      .gantt-task-card-container {
        display: flex;
      }

      .b-gridbase {
        
        .b-grid-row {

          .b-grid-cell {
            color: inherit;
            border-color: var(--arxs-nav-border-color);

            .b-tree-cell-inner {
              .b-tree-cell-value {
                .b-react-portal-container {
                  .gantt-task-record-name {
                    width: calc(55%);
                    min-width: 210px;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .gantt-buttons {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    right: 50px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;

    .gantt-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-left: 15px;
      border-radius: 50%;
      i {
        font-size: 20px;
      }
    }
  }
}
