.details-pane-wrapper {
  z-index: 0;
  position: relative;

  .details-pane-collapser {
    position: absolute;
    left: -28px;
    top: 70px;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    border: 1px solid var(--arxs-nav-border-color);
    border-right: none;
    background-color: #fff;
    color: var(--arxs-icon-color);
    background-color: var(--arxs-details-background-color);
    z-index: 5;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.fullscreen {
    position: absolute;
    width: calc(100% - var(--arxs-nav-sidebar-width));
    height: 100%;
    left: var(--arxs-nav-sidebar-width);
    top: 0;
    background-color: var(--arxs-border-shadow-color);
  }

  @media only screen and (min-width: 1224px) {
    width: var(--arxs-details-pane-width);
    transition: width 200ms;

    &.collapsed {
      width: 0px;

      .details-pane {
        width: 0px;
        overflow: hidden;
      }
    }
  }

  @media only screen and (max-width: 1224px) {
    left: 0;
    transition: left 200ms;

    &.collapsed {
      left: 100%;
      height: 100%;
    }

    position: absolute;
    width: 100%;
    min-height: 100%;
    padding: 0;

    display: flex;
    flex-direction: column;

    .details-pane {
      width: 100% !important;
      flex-grow: 1;
    }

    .details-pane-collapser {
      top: 75px;
    }
  }

  .details-pane {
    width: var(--arxs-details-pane-width);
    height: 100%;

    display: flex;
    flex-direction: column;

    background-color: var(--arxs-details-background-color);
    border-left: var(--arxs-nav-border);
    box-shadow: 0 2px 15px 0 var(--arxs-border-shadow-color);
    position: relative;

    h3 {
      color: var(--arxs-secondary-color);
      font-size: 14px;
    }

    &.fullscreen {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

      .details-pane-header {
        background-color: var(--arxs-wizard-background);
      }

      .details-pane-tabs {
        >ul {
          background-color: var(--arxs-wizard-background);
        }
      }

      .wizard-wrapper {
        padding: 30px 50px 40px 50px;
        height: 100%;
        overflow-x: hidden;
      }
    }

    .details-pane-toolbar {
      position: relative;
      flex: 0 0 40px;
      padding: 13px;
      gap: 10px;

      display: flex;

      .details-pane-toolbar-header {
        display: flex;
        flex-direction: row;

        .details-pane-toolbar-header-module {
          margin-right: 10px;
          width: 150px;
          white-space: nowrap;
          overflow: hidden;

          h2 {
            font-size: 14px;
            text-overflow: ellipsis;

            i {
              font-size: 16px;
              color: var(--arxs-icon-color);
              margin-right: 8px;
            }
          }
        }

        .details-pane-toolbar-header-uniquenumber {
          h3 {
            color: var(--arxs-secondary-color);
            margin-top: 3px;
            font-size: 12px;
          }
        }
      }

      .spacer {
        flex-grow: 1;
      }

      i {
        font-size: 14px;
        color: var(--arxs-icon-inactive-color);

        &:hover {
          color: var(--arxs-status-active-color);
          cursor: pointer;
        }

        &.fa-pencil {
          color: var(--arxs-icon-color);
        }
      }
    }

    .details-pane-header {
      display: flex;
      flex: 0 0 183px;
      padding: 20px;

      .details-pane-header-left {
        // flex-grow: 1;
        width: 199px;
        max-width: 199px;
        height: 140px;

        h1 {
          font-size: 14px;

          i {
            font-size: 16px;
            color: var(--arxs-icon-color);
            margin-right: 8px;
          }
        }

        .details-pane-header-title {
          margin-top: 5px;
          height: 68px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          textarea {
            font-size: 18px;
            font-weight: 600;
          }
        }

        h3 {
          color: var(--arxs-secondary-color);
          margin-top: 5px;
          font-size: 14px;
        }

        .details-pane-actions {
          width: 100%;
          max-width: 180px;
          margin-top: 5px;

          .dropdown-menu {
            background-color: #001233;

            @media only screen and (max-width: 400px) and (orientation: portrait) {
              left: 0px;
              right: auto;
            }

            .dropdown-menu-item:hover {
              background-color: #33466b;
            }
          }
        }
      }

      .details-pane-header-right {
        margin-left: 5px;
        width: 140px;
        height: 140px;
      }

      &.fullscreen {
        flex: 0 0 100px;
        padding: 30px;
        flex-direction: row;
        justify-content: center;

        .details-pane-header-left {
          all: revert;
          flex-grow: 1;
          display: flex;
          flex-direction: row;

          h1 {
            font-size: 14px;

            i {
              font-size: 16px;
              color: var(--arxs-icon-color);
              margin-right: 8px;
            }
          }

          .details-pane-header-left-image {
            width: 40px;
            height: 40px;
          }

          .details-pane-header-left-identification {
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            height: 100%;

            .details-pane-header-left-identification-title {
              h1 {
                font-size: 14px;
              }

              .details-pane-header-title {
                textarea {
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }

            .details-pane-header-left-identification-uniqueNumber {
              h3 {
                i {
                  font-size: 16px;
                  color: var(--arxs-icon-color);
                  margin-right: 8px;
                }

                color: var(--arxs-secondary-color);
                font-size: 14px;
              }
            }
          }

          .details-pane-header-left-status {
            padding-left: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .badge {
              margin-top: 0px;
              height: 30px;
            }
          }
        }

        .details-pane-header-right {
          display: flex;
          flex-direction: row;
          max-height: 40px;

          .details-pane-header-QR {
            border-radius: 5px;

            img {
              width: 40px;
              height: 40px;
            }
          }

          .details-pane-actions {
            width: 100%;
            height: 40px;
            width: 200px;
            padding-left: 20px;

            .dropdown-menu-button {
              line-height: 40px;
              height: 40px;
            }

            .dropdown-menu {
              background-color: #001233;

              .dropdown-menu-item:hover {
                background-color: #33466b;
              }
            }
          }
        }
      }
    }

    .details-pane-tabs {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      [role="tablist"] {
        border-bottom: 1px solid var(--arxs-nav-border-color);
        padding: 0 20px 0 20px;
        font-weight: bold;
        list-style-type: none;
        user-select: none;
        display: flex;
        font-size: 19px;

        .react-tabs__tab {
          padding: 0 12px 12px 12px;
          color: var(--arxs-primary-inactive-color);
          cursor: pointer;
          height: 30px;

          i {
            &:hover {
              color: var(--arxs-icon-color);
              cursor: pointer;
            }

            &:active {
              color: var(--arxs-icon-color);
            }
          }

          .badge {
            margin-left: 5px;
            margin-bottom: 3px;
            color: var(--arxs-primary-color);
          }
        }

        .react-tabs__tab--selected {
          color: var(--arxs-icon-color);
          border-bottom: 2px solid var(--arxs-tab-border-color);
        }
      }

      .react-tabs__tab-panel {
        font-size: 14px;

        &.react-tabs__tab-panel--selected {
          overflow-y: auto;

          .spinner-wrapper {
            overflow: hidden;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .spinner {
              width: 50px;
              height: 50px;

              div {
                border-color: var(--arxs-icon-color) transparent transparent transparent;
              }
            }
          }

          .field {
            padding: 20px 5px 0 0;

            width: 50%;

            &.full-width {
              width: 100%;
            }

            label {
              display: block;
              color: var(--arxs-primary-inactive-color);
            }

            textarea {
              margin-left: -4px;
              height: 27px;
            }

            .k-combobox {
              width: 100%;
            }

            .list-card-wrapper {
              width: 310px;
            }

            .badge.riskValue {
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: center;
              height: 30px;
              width: 111px;
            }
          }

          .field.full-width {
            label {
              margin-bottom: 10px;
            }

            .codeElement-list {
              .codeElement-list-content {
                font-size: 12px;
                padding-top: 0px;
              }
            }
          }

          .item-list {
            padding: 20px 5px 0 0;
          }

          .card-list {
            label {
              display: block;
              color: var(--arxs-primary-inactive-color);
            }

            .card-list-content-wrapper {
              > button:first-child {
                margin-left: 10px;
                margin-right: -20px;
                z-index: 100;
              }
              > button:last-child {
                margin-left: -20px;
                margin-right: 10px;
                z-index: 100;
              }
            }

            .card-list-content {
              margin: 10px 10px 10px 0;
              padding: 0px;

              .user-card-name {
                font-size: 12px;
              }
            }
          }

          .geolocation {
            padding: 20px 5px 0 0;
            width: 50%;

            label {
              display: block;
              color: var(--arxs-primary-inactive-color);
            }

            .geolocation-content {
              font-size: 12px;
              padding: 10px 10px 10px 0;
            }
          }
        }

        .Collapsible__trigger {
          display: flex;
          align-items: center;
          position: relative;
          padding: 20px;

          font-size: 16px;
          font-weight: bold;
          line-height: 19px;
          color: var(--arxs-grey-base);

          cursor: pointer;

          border-bottom: 1px solid var(--arxs-nav-border-color);

          .badge {
            margin-left: 5px;
          }

          .spacer {
            flex-grow: 1;
          }

          a {
            i {
              font-size: 12px;
            }
          }

          .terminal {
            width: 20px;
          }
        }

        .Collapsible__trigger.hide {
          display: none;
        }

        .Collapsible__trigger::after {
          position: absolute;
          right: 20px;

          font-family: "Font Awesome 6 Pro";
          font-size: 10px;
          font-weight: 400;
          content: "\f078";

          color: var(--arxs-primary-inactive-color);
        }

        .Collapsible__trigger:hover::after {
          color: var(--arxs-primary-color);
        }

        .Collapsible__contentOuter {
          width: 100%;

          .Collapsible__contentInner {
            display: flex;
            flex-wrap: wrap;
            //flex-direction: column;
            width: 100%;
            padding: 0 20px 20px 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1224px) {
    .details-pane {
      .details-pane-toolbar {
        gap: 25px;

        i {
          color: var(--arxs-icon-color);

          &.inactive {
            color: var(--arxs-icon-inactive-color);
          }
        }

        .details-pane-toolbar-header {
          .details-pane-toolbar-header-module {
            width: auto;
          }

          .details-pane-toolbar-header-uniquenumber {
            h3 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}