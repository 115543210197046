.k-calendar {

    color: var(--arxs-primary-color);
    background-color: var(--arxs-background-color);

    .k-content {
      color: var(--arxs-primary-color);
      background-color: var(--arxs-background-color);

        .k-today .k-link {
            box-shadow: none;
            color: var(--arxs-icon-color);
        }
    }

    .k-footer .k-nav-today,
    .k-calendar-header .k-today {
        color: var(--arxs-icon-color);
    }


    .k-state-selected .k-link {
        color: #fff !important;
        border: none;
        border-radius: 50%;
        background-color: var(--arxs-icon-color);
    }
}

.k-calendar-infinite .k-calendar-view::after {
    box-shadow: 0 0 38px 19px var(--arxs-list-background-color);
}