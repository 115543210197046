$header-height: 56px;

.popup-body {
  overflow: hidden;

  .upload {
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: space-between;
    height: 100%;

    .upload-cardlist-info {
      display: flex;
      flex-direction: row;
      background-color: var(--arxs-background-color);
      flex: 0 0 46px;
      padding: 10px 12px;
      overflow-x: auto;

      .upload-card-uniquenumber {
        border: 1px solid;
        border-radius: 20px;
        border-color: var(--arxs-border-shadow-color);
        background-color: var(--arxs-grid-background);
        padding: 5px 10px;
        margin: 2px;
        white-space: nowrap;
      }
    }

    .upload-dropzones {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: stretch;
      height: 100%;

      .upload-dropzone {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-right: var(--arxs-nav-border);
        overflow: hidden;

        header {
          flex: 0 0 $header-height;
          color: var(--arxs-grid-color);
          font-size: 14px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.image {
          .upload-dropzone-body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 28px;

            .upload-input {
              width: 87px;
              height: 87px;
              margin-right: 26px;
            }
            .upload-preview-container {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;

              .upload-preview {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                width: 87px;
                height: 87px;
                flex-direction: column;
                background-color: #001233b0;
                position: relative;
                margin-left: 10px;
                margin-top: 10px;

                svg {
                  cursor: pointer;
                }

                .is-preferred {
                  position: absolute;
                  top: -2px;
                  right: -2px;
                  i {
                    font-size: 15px;
                    color: var(--arxs-create-color);
                  }
                }
              }
            }
          }

          .upload-preview-actions {
            display: flex;
            flex-direction: column;
            padding-right: 5px;
            padding-left: 5px;
            padding-top: 15px;

            .upload-preview-remove {
              padding-left: 2px;
              cursor: pointer;
              margin-bottom: 5px;

              i {
                font-size: 16px;
                color: var(--arxs-secondary-color);
              }
            }

            .upload-preview-rename {
              padding-left: 2px;
              cursor: pointer;
              margin-bottom: 5px;

              i {
                font-size: 16px;
                color: var(--arxs-secondary-color);
              }
            }

            .upload-preview-set-preferred {
              padding-left: 2px;
              cursor: pointer;
              margin-bottom: 5px;

              i {
                font-size: 16px;
                color: var(--arxs-secondary-color);
              }
            }
          }
        }

        &.document {
          .upload-dropzone-body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .upload-input {
              width: 100%;
              height: 50px;
              border-radius: 5px;
            }
            .document-label {
              margin: 10px;
              width: calc(100% - 20px);
              min-width: 310px;
            }
          }
        }

        &.dzu-dropzoneActive {
          background-color: inherit;
          color: inherit;
          border: 1px solid #4d79fd;
          box-shadow: inset 0 0 18px 0 rgba(77, 121, 253, 0.6);
        }

        .upload-dropzone-body {
          flex-grow: 1;
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: flex-start;
          border: none;
          padding: 10px;

          .upload-input-empty {
            display: flex;
            height: calc(100% - #{$header-height});
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--arxs-secondary-color);
            padding: 0 38px;

            cursor: pointer;

            i.fa-plus {
              display: block;
              font-size: 20px;
              margin-bottom: 15px;
            }
            h3 {
              font-size: 13px;
            }
          }

          .upload-input {
            display: flex;
            justify-content: center;
            align-items: center;

            border: 2px dashed #acbcd9;
            border-radius: 15px;
            background-color: rgba(89, 121, 179, 0.05);
            margin: 10px;

            cursor: pointer;

            i.fa-plus {
              font-size: 20px;
              color: var(--arxs-icon-color);
            }
          }
        }
      }
    }

    .upload-submit {
      flex: 0 0 50px;
      font-size: 12px;
      background-color: var(--arxs-icon-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i.fa-plus {
        padding-left: 5px;
      }
    }
  }
}
