.planning {
  height: calc(100% + 30px);
  width: calc(100% + 60px);

  margin: -30px -10px 0 -20px;

  display: flex;
  flex-direction: row;

  &.closed {
    .planning-sidebar-wrapper {
      margin-left: -320px;
    }

    .planning-body {
      max-width: 100%;
    }
  }

  .planning-sidebar-wrapper {
    flex: 0 0 320px;
    position: relative;
    transition: margin-left 0.5s ease;

    .planning-sidebar-collapser {
      position: absolute;
      right: -30px;
      top: 18px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid var(--arxs-nav-border-color);
      border-left: none;
      background-color: #fff;
      color: var(--arxs-icon-color);
      background-color: var(--arxs-background-color);
      z-index: 5;
      cursor: pointer;
      user-select: none;
    }

    .planning-sidebar {
      overflow: hidden;

      display: flex;
      flex-direction: column;
      height: 100%;

      .k-calendar {
        flex: 0 0 310px;
        width: 320px;
        border-right: none;
      }

      .planning-sidebar-tabs {
        flex: 1 0;
        display: flex;
        flex-direction: column;

        .react-tabs__tab-list {
          flex: 0 0 auto;
        }

        .react-tabs__tab-panel--selected {
          flex-grow: 1;
          overflow: hidden;
        }
      }
    }

    .planning-sidebar-tabs {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;

      [role="tablist"] {
        border-bottom: 1px solid var(--arxs-nav-border-color);
        padding: 20px 20px 0 20px;
        font-weight: bold;
        list-style-type: none;
        user-select: none;
        display: flex;

        .react-tabs__tab {
          padding: 0 12px 12px 12px;
          color: var(--arxs-primary-inactive-color);
          cursor: pointer;
        }

        .react-tabs__tab--selected {
          color: var(--arxs-primary-color);
          border-bottom: 2px solid var(--arxs-tab-border-color);
        }
      }

      .react-tabs__tab-panel {
        font-size: 14px;
      }
    }
  }

  .planning-body {
    flex: 1;
    height: 100%;
    max-width: calc(100% - 320px);
    overflow: hidden;
    transition: max-width 0.5s ease;
    position: relative;

    display: flex;
    flex-direction: column;
    position: relative;

    .planning-body-header {
      flex: 0 0 72px;
      padding: 0 20px 0 43px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 18px;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      .planning-body-header-search {
        width: 360px;
        display: flex;
        gap: 10px;
        
        .button {
          &.active {
            border: 1px solid var(--arxs-icon-color);
            background-color: var(--arxs-icon-color);
            color: #fff;
          }
        }
      }
    }

    .planning-container {
      flex: 1 0;
      overflow: hidden;
      margin-bottom: 8px;

      .b-yscroll-pad {
        background-color: var(--arxs-background-color);
        border-left: 1px solid var(--arxs-background-color);
      }

      .b-gridbase {
        color: var(--arxs-secondary-color);
        background-color: var(--arxs-background-color);

        .b-grid-header-container {
          border-bottom: inherit;
        }

        .scheduler-row-header {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;

          .user-name {
            color: var(--arxs-primary-color);
            font-weight: 500;
          }
          .user-role {
            font-style: italic;
            font-weight: 500;
          }
        }

        .b-column-line {
          border-color: var(--arxs-nav-border-color);
        }

        .b-header {
          background-color: var(--arxs-background-color);

          .b-grid-header.b-level-0.b-depth-0 {
            color: var(--arxs-secondary-color);
            background-color: var(--arxs-background-color);
          }

          .b-sch-header-timeaxis-cell {
            color: var(--arxs-secondary-color);
            background-color: var(--arxs-background-color);
          }

          .b-horizontaltimeaxis {
            .b-sch-header-timeaxis-cell {
              border-color: var(--arxs-nav-border-color);
            }
          }
        }

        .b-grid-row {
          background-color: var(--arxs-background-color);
          border-color: var(--arxs-nav-border-color);

          .b-grid-cell {
            color: inherit;
            border-color: var(--arxs-nav-border-color);
          }
        }

        .b-grid-splitter,
        .b-grid-splitter.b-hover {
          background: var(--arxs-nav-dropdown-background-color);

          .b-grid-splitter-inner {
            background: var(--arxs-nav-dropdown-background-color);
          }
        }

        .b-grid-panel-body {
          background-color: var(--arxs-background-color);
        }

        .b-grid-body-container .b-sch-nonworkingtime {
          background: repeating-linear-gradient(45deg,
              rgba(188, 96, 96, 0.05),
              rgba(188, 96, 96, 0.05) 10px,
              rgba(152, 70, 70, 0.15) 10px,
              rgba(152, 70, 70, 0.15) 20px);
        }
      }
    }

    .planning-action-toolbar {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: calc(50% - 50px);
      margin-left: -65px;
      left: 50%;
      height: 60px;
      border-radius: 5px;
      opacity: 0.9;
      background-color: #001233;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      color: white;
      padding: 10px;
      z-index: 11;

      gap: 10px;

      .section {
        >i.fa-times {
          margin-left: 15px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      button {
        background-color: #99bcff33;
        color: white;
      }
    }

    .plannable-items-panel {
      &.closed>div:first-child {
        display: none;
      }

      &.closed>div+div {
        max-height: 0;
        min-height: 0;
      }

      >div+div {
        transition: max-height 0.2s ease-in;
        max-height: 800px;
        min-height: 260px;
      }

      .plannable-items-wrapper {
        margin-top: 4px;
        height: 100%;
        position: relative;
        transition: margin-bottom 0.5s ease;
        border-top: 1px solid var(--arxs-nav-border-color);
        box-shadow: 0 -2px 10px 0 var(--arxs-border-shadow-color);

        .plannable-items-collapser {
          position: absolute;
          right: 50px;
          top: -25px;
          padding: 5px 10px;
          border-radius: 5px 5px 0 0;
          border: 1px solid var(--arxs-nav-border-color);
          border-bottom: none;
          background-color: #fff;
          color: var(--arxs-icon-color);
          background-color: var(--arxs-input-background-color);
          z-index: 10;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}

.b-sch-event-wrap {
  color: var(--arxs-secondary-color);
  border-radius: 5px;
  background-color: var(--arxs-nav-dropdown-background-color);
  box-shadow: 3px 3px 5px var(--arxs-border-shadow-color);
  font-size: 12px !important;

  height: 36px !important;
  overflow: hidden;
  white-space: nowrap;

  &.b-sch-event-hover,
  &.b-active {
    box-shadow: 0 0 10px 0 rgb(77, 121, 255);
  }

  .b-sch-event {
    color: inherit !important;
    background-color: inherit !important;
    font-size: inherit !important;
    padding: 12px 10px;

    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;

    >div {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 5px;
    }

    &.b-sch-dirty-new.b-iscreating {
      background-color: var(--arxs-icon-background-color) !important;
      border: 2px dashed var(--arxs-icon-color) !important;
      border-radius: 5px;
    }

    &.confirmed {
      background: repeating-linear-gradient(315deg,
          var(--arxs-background-color),
          var(--arxs-background-color) 5px,
          var(--arxs-planning-moment-confirmed) 5px,
          var(--arxs-planning-moment-confirmed) 10px) !important;
    }
  }

  .progress {
    margin: -12px -10px;
    padding: 12px 10px;
  }

  .arxs-event {
    overflow: hidden;
  }

  &.b-sch-color-grey {
    background: repeating-linear-gradient(315deg,
        var(--arxs-background-color),
        var(--arxs-background-color) 5px,
        var(--arxs-nav-border-color) 5px,
        var(--arxs-nav-border-color) 10px);
  }

  &.b-sch-color-blue {
    .progress {
      background-color: var(--arxs-status-active-color-background);
    }
  }

  &.b-sch-color-orange {
    .progress {
      background-color: var(--arxs-status-warning-color-background);
    }
  }

  &.b-sch-color-red {
    .progress {
      background-color: var(--arxs-status-error-color-background);
    }
  }

  &.b-sch-color-green {
    .progress {
      background-color: var(--arxs-status-completed-color-background);
    }
  }

  &.b-sch-color-blue-gradient {
    background: repeating-linear-gradient(315deg,
        var(--arxs-status-active-color-background),
        var(--arxs-status-active-color-background) 5px,
        var(--arxs-nav-border-color) 5px,
        var(--arxs-nav-border-color) 10px);
  }

  &.b-sch-color-orange-gradient {
    background: repeating-linear-gradient(315deg,
        var(--arxs-status-warning-color-background),
        var(--arxs-status-warning-color-background) 5px,
        var(--arxs-nav-border-color) 5px,
        var(--arxs-nav-border-color) 10px);
  }

  &.b-sch-color-red-gradient {
    background: repeating-linear-gradient(315deg,
        var(--arxs-status-error-color-background),
        var(--arxs-status-error-color-background) 5px,
        var(--arxs-nav-border-color) 5px,
        var(--arxs-nav-border-color) 10px);
  }

  &.b-sch-color-green-gradient {
    background: repeating-linear-gradient(315deg,
        var(--arxs-status-completed-color-background),
        var(--arxs-status-completed-color-background) 5px,
        var(--arxs-nav-border-color) 5px,
        var(--arxs-nav-border-color) 10px);
  }


}