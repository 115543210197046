.form-popup {
  height: 100%;

  display: flex;
  flex-direction: column;
  background-color: var(--arxs-nav-background-color);
  margin: -20px -15px 0 -15px;
  position: relative;

  .form-popup-body {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .wizard-buttons {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    display: flex;
    width: 150px;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    user-select: none;
    gap: 10px;
    align-self: flex-end;

    @media only screen and (max-width: 500px) {
      right: 0;
    }

    .wizard-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;

      i {
        font-size: 20px;
      }

      &.wizard-cancel {
        background-color: var(--arxs-secondary-color);
      }
    }
  }

  .form-popup-actions {
    font-size: 12px;
    background-color: var(--arxs-icon-color);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 0 0 50px;

    .form-popup-save {
      flex-grow: 1;
      font-size: 12px;
      background-color: var(--arxs-icon-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        margin-left: 5px;
      }
    }

    .form-popup-confirm {
      flex-grow: 1;
      font-size: 12px;
      background-color: var(--arxs-create-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        margin-left: 5px;
      }
    }
  }
}