.card-list {
  width: 100%;

  &.vertical {
    max-width: 310px;
  }

  .card-list-content-wrapper {
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .card-list-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .card-list-content-cards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;
      padding-top: 10px;

      .list-card-wrapper {
        flex: 0 0 310px;
        width: 310px;
      }
    }

    .card-list-content-expansion {
      display: flex;
      justify-content: end;
      align-items: flex-end;

      .wizard-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border-color: var(--arxs-status-active-color-background);
        i {
          font-size: 20px;
        }
        background-color: var(--arxs-status-active-color-background);
      }
    }
  }
}