.planning-dashboard {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border: 1px solid var(--arxs-nav-border-color);
  border-radius: 5px;

  .planning-dashboard-tabs {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    [role="tablist"] {
      border-bottom: 1px solid var(--arxs-nav-border-color);
      padding: 20px 20px 0 20px;
      font-weight: bold;
      list-style-type: none;
      user-select: none;
      display: flex;
      font-size: 14px;
      background-color: var(--arxs-grid-background);
      overflow-x: auto;
      overflow-y: hidden;
      flex: 0 0 59px;

      .react-tabs__tab {
        padding: 0 12px 12px 12px;
        color: var(--arxs-primary-inactive-color);
        cursor: pointer;
        height: 30px;
        white-space: nowrap;

        .badge {
          margin-left: 5px;
          margin-bottom: 3px;
        }
      }

      .react-tabs__tab--selected {
        color: var(--arxs-primary-color);
        font-weight: bold;
        border-bottom: 3px solid var(--arxs-tab-border-color);
      }
    }

    .react-tabs__tab-panel {
      font-size: 14px;

      &.react-tabs__tab-panel--selected {
        overflow-y: auto;
      }
    }

    .planning-empty {
      padding: 30px;
      display: flex;
      justify-content: center;
    }

    .planning-loading {
      padding: 30px;
      display: flex;
      justify-content: center;
    }

    .planning-dashboard-report {
      position: absolute;
      top: 18px;
      right: 18px;
      font-size: 20px;
      color: var(--arxs-primary-inactive-color);

      &:hover {
        color: var(--arxs-icon-color);
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .planning-dashboard {
    .planning-dashboard-tabs {
      [role="tablist"] {
        padding: 10px 10px 0 10px;
      }
    }
  }
}