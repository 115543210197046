.form-control {
    .form-control-field {
        .rpms {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            .rpm-row {
                display: flex;
                flex-direction: row;
                gap: 10px;
                width: 100%;
                align-items: center;
                border-bottom: 1px solid var(--arxs-nav-border-color);

                .icon-image {
                    min-width: 40px;
                }

                @media only screen and (min-width: 1223px) {
                    .icon-image {
                        min-width: 40px;
                    }
                }

                .rpm-values {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;

                    .rpm {
                        flex: 0 0;
                        &.risk {
                            flex-basis: 40%;
                        }
                        &.prevention {
                            flex-basis: 60%;
                        }
                    }
                }
            }
        }
    }
}