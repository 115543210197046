.map-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  .map-view-toolbar {
    flex: 0 0 35px;
    display: flex;
  }

  .map-view-content {
    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    background-color: var(--arxs-list-background-color);
    overflow: hidden;

    .map-view-mapcontainer {
      flex-grow: 1;
      height: 100%;
      position: relative;

      #map {
        width: 100%;
        height: 100%;
      }
    }

    .layertree {
      padding: 20px;
      flex-basis: 200px;
      border-radius: 0;
      background-color: var(--arxs-background2-color);

      .ant-tree-node-content-wrapper.ant-tree-node-selected,
      .ant-tree-node-content-wrapper:hover {
        background-color: var(--arxs-background-color);
      }

      .ant-tree-switcher {
        display: none;
      }
    }
  }
}

.ant-select-dropdown {
  background-color: var(--arxs-list-background-color);
  color: var(--arxs-primary-color);
}