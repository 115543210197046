.card-lookup {
  display: flex;
  flex-direction: column;
  height: 100%;

  .card-lookup-cardlist-info {
    display: flex;
    flex-direction: row;
    background-color: var(--arxs-background-color);
    flex: 0 0 46px;
    padding: 10px 12px;
    overflow-x: auto;

    .card-lookup-documenttypes {
      display: flex;
      align-items: center;

      .dropdown {
        min-width: 200px;
      }
    }

    .card-lookup-documenttypes-addlabel {
      padding-left: 5px;
      padding-right: 5px;
    }

    .card-lookup-card-uniquenumber {
      border: 1px solid;
      border-radius: 20px;
      border-color: var(--arxs-border-shadow-color);
      background-color: var(--arxs-grid-background);
      padding: 5px 10px;
      margin: 2px;
      white-space: nowrap;
    }
  }

  .card-lookup-toolbar {
    height: 57px;
    display: flex;
    background-color: var(--arxs-input-background-color);
    border-bottom: 1px solid var(--arxs-border-shadow-color);

    .input-wrapper {
      position: relative;
      //border-radius: 5px;
      flex-grow: 1;
      padding: 1px;
      margin-left: 5px;
      border-right: 1px solid var(--arxs-border-shadow-color);

      input {
        width: calc(100% - 43px);
        margin-left: 43px;
        border: none;
        height: 55px;
      }

      &::before {
        position: absolute;
        top: 22px;
        left: 21px;
      }
    }

    .multiselect {
      flex: 0 0 200px;
      height: 57px;

      .k-multiselect-wrap {
        display: flex;
        align-items: center;

        border-top: none;
        border-right: none;
        border-radius: 0;
        border-color: var(--arxs-grid-border-color);

        li {
          color: var(--arxs-primary-color);
          background-color: var(--arxs-input-background-color);
          border: none;
        }

        .k-clear-value {
          display: none;
        }
      }
    }
  }

  .card-lookup-toolbar.mobile {
    .input-wrapper {
      margin-left: unset !important;
    }

    .structure-filter {
      width: unset !important;

      .structure-filter-header {
        width: unset !important;
        display: flex;
        justify-content: center;
        padding: 0 8px 0 5px;
        font-size: 16px;
      }

      .structure-filter-dropdown {
        left: calc(100vw - 200px);
      }
    }

    .multiselect {
      flex: 0 0 40px;

      .k-multiselect-wrap {
        padding-right: 2px !important;
      }
    }

    .sortkindtype-filter {
      width: unset !important;

      .sortkindtype-filter-header {
        font-size: 16px;
        width: unset !important;
        display: flex;
        justify-content: center;
        padding: 0 8px 0 5px;
        font-size: 16px;
      }

      .sortkindtype-filter-dropdown {
        left: calc(100vw - 200px);
      }
    }
  }

  .card-lookup-content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;
    background-color: var(--arxs-list-background-color);
    flex-direction: row;
    overflow: hidden;

    .card-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 10px 15px;
      gap: 10px;

      .list-card-wrapper {
        flex: 0 0 310px;
        width: 310px;
      }
    }

    .tree-nav-container {
      min-width: 340px;
      height: 100%;
      padding-right: 10px;
      overflow: auto;
    }

    .tree-nav-container.mobile {
      width: 100%;

      .list-card-wrapper.mobile {
        width: 100%;
      }
    }

    .tree-card-content {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      background-color: var(--arxs-list-background-color);
      border-radius: 5px;
    }
  }
  .card-lookup-selected {
    background-color: var(--arxs-icon-color);
    color: white;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    i {
      margin-left: 5px;
    }

    cursor: pointer;

    &.disabled {
      background-color: var(--arxs-icon-inactive-color);
      color: var(--arxs-primary-color);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.card-lookup-buttons {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 55px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;

  .card-lookup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin-left: 15px;
    border-radius: 50%;
    i {
      font-size: 20px;
    }
  }
}
