.star-grid {
    position: absolute;
    top: 0px;
    left: 0px;

    .star {
        will-change: opacity;
        animation: pulse var(--star-animation-duration, 1000ms) infinite backwards;
        animation-delay: var(--star-animation-delay, 0);
    }
}

@keyframes pulse {
    48% {
        opacity: 0;
    }

    50% {
        opacity: 0.8;
    }

    52% {
        opacity: 0;
    }
}