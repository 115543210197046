.react-tabs__tab-panel {
  .form {
    font-size: 12px;
  }
}
.form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: center;

  .form-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    .spinner {
      > div {
          border-color: var(--arxs-icon-color) transparent transparent transparent;
      }
    }
  }

  .header {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-right: 50px;
  }

  .card-list .card-list-content .card-list-content-cards {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 15px 0;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 26px;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: var(--arxs-secondary-color);
    border: 1px solid var(--arxs-border-shadow-color);
    background-color: var(--arxs-border-shadow-color);
  }

  .title {
    display: flex;
    margin-left: 15px;
    font-weight: bold;
    font-size: 16px;
    flex-grow: 1;

    .badge {
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  .form-actions {
    display: flex;
    align-items: center;

    .mark-all-ok {
      border: 1px solid var(--arxs-nav-border-color);
      border-radius: 50%;
      padding: 5px;

      &:hover, &.all-ok {
        cursor: pointer;
        color: #fff;
        border: 1px solid var(--arxs-create-color);
        background: linear-gradient(180deg, var(--arxs-create-color-gradient-lo), var(--arxs-create-color-gradient-hi));
      }
    }
  }

  .Collapsible__trigger {
    display: flex;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid var(--arxs-nav-border-color);
    color: var(--arxs-form-header-color);
    align-items: center;
    padding: 0 !important;

    &:after {
      position: absolute;
      top: calc(50% - 5px);
      right: 28px;

      font-family: "Font Awesome 6 Pro";
      font-size: 10px;
      font-weight: 400;
      content: "\f078";
      transition: 200ms ease-in-out;

      color: var(--arxs-form-header-color);
    }
  }

  .Collapsible__trigger:hover {
    &:after {
      color: var(--arxs-icon-color);
    }
  }

  .Collapsible__trigger.is-closed {
    &:after {
      transform: rotate(-180deg);
    }
  }

  .Collapsible__contentOuter {
    width: 100%;

    .Collapsible__contentInner {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .form-body {
    flex-grow: 1;
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    position: relative;
    
    .form-header {
      > h1 {
        margin-bottom: 10px;
      }

      > h3 {
        font-size: 14px;
        color: var(--arxs-secondary-color);
        margin-bottom: 30px;
      }

      > .separator {
        margin-bottom: 20px;
      }
    }
  }

  .form-body-bulk-actions {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  
  .subject {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px;
      justify-content: left;

      .title {
        font-size: 18px;
      }
    }
  }

  .sections {
    flex-grow: 1;

    .section {
      color: var(--arxs-secondary-color);
      background-color: var(--arxs-form-background);
      border-radius: 10px;
      border: 1px solid var(--arxs-form-border-color);

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px;
        justify-content: left;

        .title {
          font-size: 18px;
        }
      }

      .section-body {
        width: 100%;
        padding: 10px 0px;
        color: var(--arxs-grey-base);
        display: flex;
        flex-direction: column;
        gap: var(--arxs-field-gap);

        .row {
          width: 100%;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--arxs-field-gap);
          align-items: flex-start;
        }

        .row ~ .row {
          h3 {
            font-size: 14px;
            margin-top: 25px;
            color: var(--arxs-secondary-color);
          }
        }

        .item-list {
          .row {
            margin: 5px 0;
          }

          .header {
            gap: var(--arxs-field-gap);
          }
        }

        .field {
          flex: 1 0;

          label {
            display: block;
            font-size: 14px;
            color: var(--arxs-form-label-color);
          }

          textarea {
            height: 27px;
          }

          .k-combobox,
          .k-widget:not(.k-switch) {
            width: 100%;
          }

          .k-widget.k-editor {
            margin-top: 10px;
          }
        }

        .item-list-table {
          .header {
            padding: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1224px) {
    .sections {
      .section {
        .body {
          padding: 10px 15px;
        }
      }
    }
  }

  @media only screen and (min-width: 1224px) {
    .validation-summary {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0;
      right: 0;
      flex-basis: 225px;
      margin-left: 40px;
    }
  }
}
