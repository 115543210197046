.library-popup {
    background-color: var(--arxs-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    position: relative;
    width: 70vw;
    height: 60vh;
    overflow: hidden;

    .library-popup-close {
        position: absolute;
        right: 14px;
        top: 14px;
        cursor: pointer;
    }

    .library-popup-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;

        .content {
            display: flex;
            flex-direction: row;
            height: calc(100% - 50px);
            gap: 10px;

            .spinner {
                > div {
                    border-color: var(--arxs-icon-color) transparent transparent transparent;
                }
            }

            .button {
                display: flex;
                align-items: center;
                gap: 10px;

                i {
                    font-size: 20px;
                }
            }
        }

        .buttons {
            flex-basis: 40px;
            display: flex;
            justify-content: center;
            bottom: 10px;

            .button {
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
