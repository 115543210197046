.report-header-footer-editor {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .report-header, .report-footer {
        display: flex;
        flex-direction: column;

        > label:not(:first-child) {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column;

        .dropdown {
            width: 100% !important;
        }
    }

    .header-colors {
        display: flex;
        
        input[type=color] {
            border: none;
        }
    }

    .dzu-dropzone {
        border: none;
        width: 250px;

        cursor: pointer;
        border: 2px dashed #acbcd9;
        background-color: var(--arxs-border-shadow-color);
        color: var(--arxs-secondary-color);

        .dzu-inputLabel {
            display: flex !important;
            align-items: center;
            justify-content: center;

            > i {
                font-size: 32px;
            }
        }

        .dzu-previewContainer {
            padding: 0 10px;
            .dzu-previewImage {
                max-height: 80px;
                max-width: 200px;
            }
        }
    }

    .report-preview {
        width: 100%;

        .report-preview-document {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 340px;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
            padding: 15px;

            .report-preview-header, .report-preview-footer {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                img {
                    height: 24px;
                }
            }
            .report-preview-header {
                align-items: end;
                height: 40px;
                border-bottom: 1px solid #bbb;
                padding: 0 0 5px 0;
            }
            .report-preview-body {
                font-size: 8px;
            }
            .spacer {
                flex-grow: 1;
            }
            .report-preview-footer {
                height: 40px;
                border-top: 1px solid #bbb;
                padding: 5px 0 0 0;
            }

            .report-preview-page {
                display: flex;
                gap: 5px;
                label {
                    font-size: inherit !important;
                    color: #999 !important;
                }
            }
        }
    }
}