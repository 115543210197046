.form-control {
    .form-control-title {
        .form-control-title-value {
            .checklist-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                .checkbox {
                    height: 100%;
                    display: flex;
                    padding-right: 10px;
                    margin-top: 5px;
                    align-items: flex-start;
                }

                textarea {
                    font-size: 16px;
                    padding-bottom: 10px;
                    border: none;
                }
            }
        }
    }
}