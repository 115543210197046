.validation-summary {
    background-color: var(--arxs-wizard-validation-background-color);
    border-radius: 10px;
    border: 1px solid var(--arxs-wizard-validation-border-color);
    padding: 18px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
