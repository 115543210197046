.planning-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;

  border-radius: 0 5px 5px 5px;
  background-color: var(--arxs-list-background-color);
  box-shadow: 0 2px 4px 0 var(--arxs-border-shadow-color);

  .planning-list-columns {
    display: flex;
    color: var(--arxs-secondary-color);
    gap: 15px;
    padding: 10px;

    &:hover {
      background-color: var(--arxs-details-background-color);
    }

    .planning-list-column-body {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      flex-wrap: wrap;

      .planning-list-column-counters {
        display: flex;
        flex-direction: row;
        gap: 5px;
        color: var(--arxs-status-active-color);
        font-size: 12px;
        font-weight: 500;
        align-items: center;

        .badge {
          height: 22px;
          width: fit-content;
        }

        .list-card-bullet {
          color: var(--arxs-primary-inactive-color);
        }

        .list-card-timestamp {
          .planning-list-timestamp-overtime {
            color: var(--arxs-status-error-color);

            i {
              padding-right: 5px;
            }
          }
        }

        .list-card-target-date {
          width: fit-content;
          color: var(--arxs-secondary-color);
          background-color: var(--arxs-border-shadow-color);
          border-radius: 3px;
          padding: 3px;
        }
      }

      .planning-list-column-details {
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 10px;

        .card-list .card-list-content {
          width: 630px;

          .card-list-content-cards {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 15px 0;
          }
        }

        .list-card-wrapper {
          width: 100%;
          max-width: 310px;
        }

        .planning-list-column-location {
          flex: 1;
          font-size: 12px;
        }

        .planning-list-column-title {
          font-size: 16px;
          color: var(--arxs-primary-color);
          font-weight: bold;
        }

        .planning-list-column-description {}
      }

      .planning-list-column-assignees {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        color: var(--arxs-primary-color);
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        align-items: center;

        >div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}

@media print {
  .planning-list {
    display: block;

    .planning-list-row {
      padding-top: 20px;
      display: block;
      break-inside: avoid;

      .planning-list-columns {
        display: block;

        .planning-list-column-actions {
          display: none !important;
        }

        .planning-list-column-description {
          text-overflow: unset !important;
          word-wrap: break-word !important;
          white-space: normal !important;
        }
      }
    }
  }
}