$header-height: 56px;

.popup-body {
  overflow: hidden;

  .document-preview {
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: space-between;
    height: 100%;

    .document-preview-documentmanagementlink {
      height: 50px;
      font-size: 12px;
      background-color: var(--arxs-icon-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .document-preview-document {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      height: 100%;

      .preview_container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 100%;
        width: 100%;
        position: relative;

        iframe {
          width: 100%;
          border: none;
        }
      }

      .editor_container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 100%;
        width: 100%;

        iframe {
          width: 100%;
          border: none;
        }
      }
    }

    .document-actions {
      height: 50px;
      display: flex;
      flex-direction: row;
      background-color: var(--arxs-icon-color);
      color: white;
      justify-content: flex-end;
      width: 100%;

      .document-download {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
       

        .document-download-action {
          color: white;
          cursor: pointer;
          font-size: 12px;
        }
      }

      .document-edit {
        display: flex;
        width: 50px;
        justify-content: center;
        align-items: center;
      
        .document-edit-action {
          cursor: pointer;
          
          i.fa-pencil {
            font-size: 16px;
          }
        }
      }
    }
  }
}
