.treeview {
  height: 100%;
  .kendo-treeview {
    height: 100%;
    .k-treeview {
      overflow-y: auto;

      .k-treeview-top,
      .k-treeview-mid,
      .k-treeview-bot {
        background-image: url("/static/treeview/treeview-nodes.png");
        background-repeat: no-repeat;
        margin-left: -16px;
        padding-left: 16px;
      }
      .k-treeview-item {
        background-image: url("/static/treeview/treeview-line.png");
        background-repeat: no-repeat;
        background-position-x: 5px;

        > .k-treeview-top, > .k-treeview-mid, > .k-treeview-bot {
          width: 100%;
          > .k-treeview-leaf {
            width: 100%;
            > .k-treeview-leaf-text {
              width: 100%;
            }
          }
        }
      }
      .k-first {
        background-repeat: no-repeat;
        background-position: 0 16px;
      }
      .k-last {
        background-image: none;
        .k-treeview-top {
          background-position: -25px -66px;
        }
      }
      .k-treeview-top {
        background-position: -86px 4px;
      }
      .k-treeview-bot {
        background-position: -64px -22px;
      }
      .k-treeview-mid {
        background-position: -42px -42px;
      }
      .k-group .k-last .k-treeview-bot {
        background-position: -64px -22px;
      }
    }
  }

  .treeview-item-text {
    display: flex;
    align-items: center;
  }
}
