.codeElement-label {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
  width: 310px;
  height: 60px;
  background-color: var(--arxs-list-card-background-color);
  padding: 10px;

  .codeElement-icon-wrapper {

    .codeElement-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      color: var(--arxs-secondary-color);
      margin-right: 10px;
    }

    .codeElement-icon.selected {
      color: var(--arxs-codeElement-label-selected-color);
    }
  }

  .codeElement-info-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-weight: bold;
    color: var(--arxs-secondary-color);

    .codeElement-info-breadcrumb {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
    .codeElement-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bolder;
    }
  }

  .codeElement-actions {
    display: flex;
    flex-direction: row;
    font-size: 12px;

    .codeElement-remove {
      padding-left: 2px;
      cursor: pointer;

      i {
        color: var(--arxs-secondary-color);
      }
    }
  }

  .codeElement-name.selected {
    color: var(--arxs-codeElement-label-selected-color);
  }

  .codeElement-checkbox {
    padding-right: 10px;
  }
}

.codeElement-label.selected {
  background-color: var(--arxs-codeElement-label-selected-color-background);
}
