.signature-popup {
    display: flex;
    flex-direction: column;
    height: 100%;
    user-select: none;
    
    .signature-popup-body {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        iframe {
            height: 100%;
            width: 100%;
            border-radius: 5px;

            &.signed {
                border: 5px solid var(--arxs-status-completed-color);
            }

            &.tosign {
                border: 5px solid var(--arxs-status-warning-color);
            }
        }

        .info-badge {
            position: absolute;
            padding: 5px 10px;
            border-radius: 0 10px 0 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            &.signed {
                background-color: var(--arxs-status-completed-color);
                color: white;
            }

            &.tosign {
                color: var(--arxs-tag-label-selected-color);
                background-color: var(--arxs-tag-label-selected-color-background);
            }
        }

        .spinner {
            width: 50px;
            height: 50px;

            div {
                border-color: var(--arxs-icon-color) transparent transparent transparent;
            }
        }
    }
    
    .signature-popup-actions {
        flex-basis: 40px;
        display: flex;
        flex-direction: row;
    }

    .signature-popup-actions {
        flex-basis: 50px;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .signature-popup-actions-button {
            flex: 1 1;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.sign {
                background-color: var(--arxs-icon-color);
                color: white;
            }

            &.unsign {
                color: var(--arxs-tag-label-selected-color);
                background-color: var(--arxs-tag-label-selected-color-background);
            }

            &.confirm {
                background-color: var(--arxs-status-completed-color);
                color: white;
            }
        }
    }

    .signature-popup-nav {
        flex-basis: 40px;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .signature-popup-nav-section {
            background-color: var(--arxs-list-background-color);

            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-weight: 600;

            .fa-check {
                color: var(--arxs-status-completed-color);
            }

            .fa-question {
                color: var(--arxs-tag-label-selected-color-background);
            }
        }

        .signature-popup-nav-button {
            background-color: var(--arxs-list-background-color);

            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            cursor: pointer;

            &:hover {
                background-color: var(--arxs-list-card-background-color);
            }

            &.inactive {
                cursor: not-allowed;

                &:hover {
                    background-color: var(--arxs-list-background-color);
                }
            }
        }
    }
}