.k-widget.k-editor {
  height: 225px;

  &.read-only {
    height: initial;
  }

  .k-widget.k-toolbar {
    .k-button-group {
      .k-widget.k-dropdown.k-header.k-group-start.k-group-end {
        min-width: 200px;
      }
      .k-widget.k-dropdown.k-group-start.k-group-end {
        margin-top: 0;
        min-width: 200px;
      }
    }
  }
  .k-editor-content {
    iframe {
      font-family: var(--arxs-font-family);
      font-size: 90px;
      height: 100% !important;
    }
  }
}
