.selection-label {
  padding-top: 10px;
  
  .selection-label-action {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px dashed #acbcd9;
    border-radius: 5px;
    background-color: var(--arxs-border-shadow-color);
    height: 60px;

    cursor: pointer;

    color: var(--arxs-secondary-color);
    font-weight: bold;
    font-size: 12px;

    max-width: 310px;

    i.fa-plus {
      margin-left: 10px;
    }
  }
}
