.wizard-popup {
    display: flex;
    flex-direction: column;
    height: 100%;

    .wizard-popup-body {
       
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
        overflow:hidden;

        .wizard-wrapper {
            padding: 10px;
            overflow: auto;
        }
    }
}
