.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .form-container-has-document{
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        color: var(--arxs-status-warning-color);
    }
}