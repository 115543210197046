.tag-label {
    display: flex;
    align-items: center;
    flex-direction: row;

    border-radius: 5px;
    box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
    width: 310px;
    height: 60px;
    background-color: var(--arxs-tag-label-color-background);
    color: var(--arxs-primary-color);
    padding: 10px;

    .tag-icon-wrapper {

        .tag-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            color: var(--arxs-tag-label-color);
            margin-right: 10px;
        }

        .tag-icon.selected {
            color: var(--arxs-tag-label-selected-color);
        }
    }

    .tag-info-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;

        .tag-info-breadcrumb {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
        }
        .tag-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bolder;
        }
    }

    .tag-actions {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        height: 100%;

        .tag-remove {
            padding-left: 2px;
            cursor: pointer;

            i {
                color: var(--arxs-secondary-color);
            }
        }
    }

    .tag-name.selected {
        color: var(--arxs-tag-label-selected-color);
    }

    .tag-checkbox {
        padding-right: 10px;
    }
}

.tag-label.selected {
    background-color: var(--arxs-tag-label-selected-color-background);
}
