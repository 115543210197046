.error-boundary {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    gap: 30px;
    .error-title {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
    .error-splash {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      gap: 30px;
      img {
        height: 50px;
        border-radius: 5px;
        // box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.2);
      }
      span {
        font-size: 14px;
      }
    }
  }