.planning-progress-container {
    position: relative;
    width: 100%;
    flex: 0 0 20px;
    height: 20px;
    line-height: 20px;
    background-color: var(--arxs-icon-background-color);
    
    .planning-progress {
        position:absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: var(--arxs-icon-color);
    }
    span {
        position:absolute;
        top: 0;
        left: 0;
        margin-left: 5px;
    }
}