.popup-body {
    overflow: hidden;

    .image-preview {
        display: flex;
        flex-direction: column;
        height: 100%;

        .image-preview-images {
            height: calc(100% - 50px);

            .carousel-root {
                height: 100%;
                display: flex;
                flex-direction: column;

                .carousel-slider {
                    height: 100%;
                }
                
                .carousel {
                    .slider-wrapper {
                        height: 100% !important;

                        .slider {
                            height: 100% !important;

                            .slide {
                                height: 100%;
                                background-color: var(--arxs-background2-color);

                                .image-item {
                                    height: 100%;

                                    .react-transform-wrapper {
                                        height: 100%;
                                        width: 100%;

                                        .react-transform-component {
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            img {
                                                max-height: 100%;
                                                max-width: 100%;
                                                object-fit: contain;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .thumbs {
                        display: flex;
                        flex-direction: row;

                        .thumb {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 4px;
                            height: 100px;

                            border: 0;

                            &:hover {
                                border: 1px solid var(--arxs-icon-color);
                                border-radius: 5px;
                                cursor: pointer;
                            }

                            &.selected {
                                border: 1px solid var(--arxs-icon-color);
                                border-radius: 5px;
                            }

                            img {
                                width: auto !important;
                                height: auto !important;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .image-preview-download {
            flex: 0 0 50px;
            font-size: 12px;
            background-color: var(--arxs-icon-color);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
