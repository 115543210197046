.page-header {
    width: 100%;
    height: var(--arxs-nav-header-height);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: var(--arxs-nav-background-color);
    border-bottom: var(--arxs-nav-border);
    box-shadow: 0 2px 6px 0 var(--arxs-border-shadow-color);

    h1 {
        font-size: 13px;
        font-family: var(--arxs-nav-title-font-family, "Roboto Condensed", sans-serif);
        margin: 0;
    }

    h3 {
        line-height: 17px;
        font-size: 10px;
        font-family: var(--arxs-nav-title-font-family, "Roboto Condensed", sans-serif);
        margin-left: 5px;
        color: var(--arxs-secondary-color);
    }

    >div {
        padding: 20px;
        text-align: center;

        i {
            font-size: 19px;
            color: var(--arxs-icon-inactive-color);
        }
    }

    div:hover {
        >i {
            color: var(--arxs-icon-color);
        }
    }

    .logo {
        width: auto;
        display: flex;
        align-items: center;

        img {
            height: 19px;
            margin-right: 5px;
        }
    }

    .spacer {
        flex-grow: 1;
        padding: 20px 0 20px 0;
    }

    .tile {
        width: 59px;
        border-left: var(--arxs-nav-border);
        position: relative;
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .tile-badge {
            position: absolute;
            right: 10px;
            top: 15px;
            color: #ffffff;
            background-color: var(--arxs-badge-accent-background-color);
            border-radius: 7px;
            height: 14px;
            width: 21px;
            font-size: 8px;
            font-weight: bold;
            line-height: 15px;
            text-align: center;
            vertical-align: middle;
            padding: 0;
        }
    }

    .profile {
        width: auto;
        height: inherit;
        line-height: inherit;
        background-color: inherit;
        border-top: none;
        border-right: none;
        border-left: var(--arxs-nav-border);
        border-bottom: var(--arxs-nav-border);
        border-radius: 0;

        .avatar {
            margin-right: 10px;
        }

        i.fa-chevron-down {
            margin-left: 5px;
            font-size: 10px;
        }

        li {
            display: block;
            text-decoration: none;
        }

        >li {
            float: left;
            position: relative;
        }
    }
}
