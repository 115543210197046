.popup-body {
    overflow: hidden;

    .avatar-editor {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .avatar-editor-avatar {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 100%;
            align-items: center;
            padding-top: 20px;
        }

        .avatar-editor-controls {
            display: flex;
            flex-direction: column;
            padding-top: 5px;
            padding-bottom: 5px;

            .avatar-editor-controls-zoom {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-bottom: 5px;

                &.control {
                    padding-left: 5px;
                }

                &:hover {
                    cursor: pointer;
                }

                i {
                    font-size: 14px;
                    color: var(--arxs-icon-inactive-color);

                    &:hover {
                        color: var(--arxs-status-active-color);
                        cursor: pointer;
                    }
                }
            }
            .avatar-editor-controls-rotation {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &.control {
                    padding-left: 5px;
                }

                &:hover {
                    cursor: pointer;
                }

                i {
                    font-size: 14px;
                    color: var(--arxs-icon-inactive-color);

                    &:hover {
                        color: var(--arxs-status-active-color);
                        cursor: pointer;
                    }
                }
            }
        }

        .avatar-editor-submit {
            flex: 0 0 50px;
            font-size: 12px;
            background-color: var(--arxs-icon-color);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            i.fa-plus {
                padding-left: 5px;
            }
        }
    }
}
