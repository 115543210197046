@media only screen and (max-width: 1223px) {
    .login-content {
        .form {
            width: 100% !important;
            height: 100%;
            padding: 0 !important;
            justify-content: start !important;

            .logo {
                width: 100%;
                padding: 24px 20px 19px 20px !important;
                text-align: initial;

                img {
                    height: 16px;
                }

                h1 {
                    font-weight: bold;
                }

                border-bottom: 1px solid var(--arxs-input-border-color);
            }

            .form-content {
                padding: 23px 30px 23px 30px !important;
            }
        }

        .splash {
            width: 100% !important;
            height: 100%;
            background-size: cover;

            .splash-content {
                .motto {
                    h2 {
                        font-size: 22px !important;
                        line-height: 29px !important;
                    }
                }
            }

            .splash-button {
                position: absolute;
                bottom: 40px;
                z-index: 1003;

                button {
                    width: calc(100% - 60px) !important;
                }
            }
        }
    }
}

.login-content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;

    color: var(--arxs-login-form-color);
    background-color: var(--arxs-login-form-background);

    .logo {
        font-size: 7px;
        letter-spacing: 3.69px;
        line-height: 17px;
        font-family: var(--arxs-font-condensed, "Roboto Condensed", sans-serif);
        text-align: center;

        h1 {
            display: inline-block;
            font-weight: normal;
            vertical-align: middle;
        }

        img {
            vertical-align: middle;
            height: 24px;
            margin-right: 15px;
        }
    }

    .splash-logo {
        text-align: left;
        margin-bottom: 30px;
        cursor: pointer;

        img {
            height: 75px;
        }
    }

    .form {
        width: 440px;
        height: 100%;
        padding: 23px 50px 23px 50px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .form-content {
            width: 100%;

            h1 {
                height: 25px;
                font-size: 22px;
                font-weight: bold;
                line-height: 25px;
                margin-bottom: 30px;
            }
        }

        .social {
            h2 {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            button {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;

                img {
                    margin-right: 16px;
                }
            }
        }

        .grid-2 {
            display: flex;
            justify-content: space-between;
        }

        .field {
            margin-bottom: 30px;

            label {
                opacity: 0.3;
                font-size: 14px;
                line-height: 19px;
                display: inline;
            }

            .far::before {
                position: absolute;
                top: 13px;
                left: 10px;
                font-size: 14px;
                opacity: 0.6;
            }

            .input-wrapper {
                display: block;
                margin-top: 10px;
                position: relative;

                input {
                    margin-right: 20px;
                    text-indent: 20px;
                }
            }

            input[type="text"],
            input[type="password"] {
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                font-size: 14px;
                line-height: 19px;
                padding: 10px 10px 10px 10px;
                border: 1px solid var(--arxs-input-border-color);
                border-radius: 3px;
            }

            input[type="checkbox"] {
                display: block;
                margin-right: 10px;
            }

            input[type="checkbox"] + label {
                display: block;
                opacity: 1;
                font-size: 12px;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 100%;
            border: 0;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            background-color: var(--arxs-icon-color);
            margin-bottom: 20px;
            cursor: pointer;

            &:hover,
            &:focus {
                box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
            }

            .spinner {
                width: 32px;
                height: 32px;

                div {
                    border-width: 2px;
                }
            }
        }

        .social {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;

            button {
                height: 61px;
                border: solid 1px;
                border-radius: 5px;
                background-color: #fff;
                border-color: rgba(153, 166, 204, 0.3);
                color: #001b4d;

                img {
                    height: 35px;
                    float: left;
                    margin-left: 16px;
                }
            }
        }
    }

    .splash {
        height: 100%;
        width: calc(100% - 440px);
        color: var(--arxs-login-splash-color, #fff);
        background: black;
        overflow: hidden;
        display: flex;
        position: relative;
        flex-grow: 1;
        flex-direction: column;
        background-size: cover;

        .splash-content {
            position: -webkit-sticky;
            /* Safari */
            position: sticky;
            display: flex;
            flex-direction: column;
            bottom: 200px;
            z-index: 100;
            margin-left: 20px;
            height: 100%;
            justify-content: flex-end;

            .motto {
                text-align: left;
                margin-bottom: 100px;

                h2 {
                    display: inline-block;
                    width: 100%;
                    color: #fff;
                    font-size: 30px;
                    line-height: 33px;
                    font-weight: normal;
                    margin-bottom: 37px;
                    white-space: pre-line;
                    font-weight: bold;
                }
            }
        }

        .splash-button {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            button {
                height: 40px;
                width: 100%;
                border: 0;
                border-radius: 4px;
                font-size: 12px;
                color: var(--arxs-icon-color);
                background-color: #fff;
                cursor: pointer;
                z-index: 2;

                &:hover,
                &:focus {
                    box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
                }
            }
        }
    }
}
