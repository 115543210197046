.settings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .settings-header {
    width: 100%;
    margin-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .module {
      font-size: 16px;
      margin-left: 10px;
      cursor: pointer;
      color: var(--arxs-secondary-color);

      &:hover {
        color: var(--arxs-icon-color);
      }
    }
  }

  .settings-tabs {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    [role="tablist"] {
      border-bottom: 1px solid var(--arxs-nav-border-color);
      padding: 0 20px 0 20px;
      font-weight: bold;
      list-style-type: none;
      user-select: none;
      display: flex;

      .react-tabs__tab {
        padding: 0 12px 12px 12px;
        color: var(--arxs-primary-inactive-color);
        cursor: pointer;
      }

      .react-tabs__tab--selected {
        color: var(--arxs-primary-color);
        border-bottom: 2px solid var(--arxs-tab-border-color);
      }
    }

    .react-tabs__tab-panel--selected {
      padding: 30px 0;
    }
  }
}
