.content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .page-main {
    width: 100%;
    height: calc(100% - var(--arxs-nav-header-height));
    display: flex;
    flex-direction: row;
    position: relative;
    overflow-x: hidden;

    .page-content {
      height: 100%;
      flex: 1 1;
      padding: 30px 10px 0 20px;
      overflow-x: hidden;
      z-index: 0;
    }
  }

  @media only screen and (min-width: 1224px) {
    .page-main {
      overflow-y: hidden;
    }
  }

  @media only screen and (max-width: 1224px) {
    .page-main {
      .page-content {
        padding: 20px 15px 0 15px;
      }
    }
  }

  /* The emerging W3C standard
   that is currently Firefox-only */
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--arxs-secondary-color) var(--arxs-scrollbar-background-color);
  }

  /* Works on Chrome/Edge/Safari */
  ::-webkit-scrollbar {
    width: 8px;
    height: 16px;
    background: var(--arxs-scrollbar-background-color);
  }

  ::-webkit-scrollbar-track {
    background: var(--arxs-scrollbar-background-color);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--arxs-secondary-color);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--arxs-scrollbar-background-color);
  }

  // ::-webkit-resizer {
  //   background-color: var(--arxs-scrollbar-background-color);
  // }

  @media only screen and (max-width: 500px) {
    ::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      background: var(--arxs-scrollbar-background-color);
    }
  }
}

.beamerAnnouncementBar {
  background: linear-gradient(180deg, #4D79FF 0%, #4D56FF 100%);
}

html > body > #beamerLastPostTitle { display: none !important; }