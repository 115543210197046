.diagnostics-kendo {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .groups {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .group {
            flex-basis: 400px;
            position: relative;

            h3 {
                position: absolute;
                top: -8px;
                left: 10px;
            }

            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            background-color: var(--arxs-list-background-color);
            border: solid 1px var(--arxs-nav-border-color);
            border-radius: 2px;
        }
    }
}