.dropdown-menu-button {
  color: var(--arxs-primary-color);
  border: 1px solid var(--arxs-grid-border-color);
  background-color: var(--arxs-input-background-color);
  height: 32px;
  line-height: 32px;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  user-select: none;

  &.icon {
    color: white;
    background-color: var(--arxs-icon-color);
    border: 1px solid var(--arxs-icon-border-color);
  }

  &.report {
    color: white;
    background-color: #99bcff33;
    border: 1px solid #99bcff33;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      font-size: 16px;
    }
  }

  i.fa-chevron-down {
    margin-left: 5px;
    font-size: 10px;
  }

  li {
    display: block;
    text-decoration: none;
  }

  >li {
    float: left;
    position: relative;
  }

  li:hover {
    cursor: pointer;
  }

  &.above {
    .dropdown-menu {
      top: initial;
      bottom: 100%;
      border-radius: 5px 5px 0 5px;
      box-shadow: 4px -4px 10px 0 var(--arxs-border-shadow-color);
    }
  }

  &.disabled {
    opacity: 0.7;
  }

  &.open {
    opacity: 1;
    
    .dropdown-menu {
      opacity: 1;
      box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
    }
  }

  .dropdown-menu {
    border: var(--arxs-nav-border);
    border-radius: 5px 0 5px 5px;
    background-color: var(--arxs-nav-dropdown-background-color);
    box-shadow: 4px 4px 10px 0 var(--arxs-border-shadow-color);

    position: absolute;
    top: 100%;
    right: 0px;

    width: 180px;
    max-height: 400px;
    padding: 8px 0 8px 0;
    user-select: none;
    line-height: initial;

    z-index: 999;

    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
    display: none;
    overflow: auto;

    li {
      clear: both;
      width: 100%;

      .dropdown-menu-item {
        height: 32px;
        padding: 8px 0 8px 0;

        .dropdown-menu-item-inner {
          display: flex;
          padding: 0 0 0 15px;

          .checkbox {
            margin-right: 10px;
          }

          i {
            margin-right: 10px;
            font-size: 15px;
            width: 16px;
          }

          .title {
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .dropdown-menu-item:hover {
        background-color: var(--arxs-nav-dropdown-hover-color);

        >.dropdown-menu-item-inner {
          border-right: 2px solid var(--arxs-icon-color);

          >i {
            color: var(--arxs-nav-icon-color);
            text-shadow: var(--arxs-nav-dropdown-hover-shadow);

            &.far.fa-sun {
              color: #ebc334;
            }
          }
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.open>ul,
  &.open>ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}