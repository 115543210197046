.form-control {
    border: 1px solid var(--arxs-nav-border-color);
    border-radius: 5px;
    width: 100%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    background-color: var(--arxs-background-color);

    .form-control-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .form-control-title-value {
            display: flex;
            flex-direction: row;
            padding-bottom: 10px;
            font-size: 14px;
            width: 100%;
            font-weight: bold;

            span {
                color: red;
                padding-right: 5px;
            }

            .checklist-item {
                .textarea {
                    word-break: break-all;
                }
            }
        }

        .form-control-subtitle {
            color: var(--arxs-status-active-color);
            font-size: 12px;
            display: flex;
            align-items: flex-start;
            text-align: center;
            font-weight: bold;

            i {
                margin-right: 8px;
            }

            .form-bullet {
                color: var(--arxs-secondary-color);
                margin: 0 8px;
            }

            .form-control-attachment-chevron {
                padding-left: 10px;
                color: var(--arxs-secondary-color);
            }
        }
    }

    .form-control-field {
        .field-ellipsis {
            color: var(--arxs-secondary-color);
            border: 1px solid var(--arxs-border-shadow-color);
            background-color: var(--arxs-border-shadow-color);
            padding: 5px;
            border-radius: 4px;
        }

        .k-input {
            input {
              border: none;
            }
          }
    }
}

.form-control.missing-value {
    border: 1px solid var(--arxs-badge-accent-background-color);
}

.form-control.required {
    border-left: 5px solid var(--arxs-status-error-color);
}

.form-control-attachments {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .form-control-attachments-row {
        color: var(--arxs-secondary-color);
        padding-bottom: 10px;
        border-top: 1px solid;
        padding-top: 10px;

        .attachment-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            label {
                display: block;
                color: var(--arxs-primary-inactive-color);
                font-size: 12px;
                line-height: 16px;
            }

            .attachment-author {
                font-size: 14px;
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 11px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .avatar {
                    margin-right: 10px;
                }

                .attachment-author-name {
                    flex-grow: 1;
                }

                .attachment-action {
                    cursor: pointer;
                }
            }

            .attachment-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;

                .image.preview {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}

.form-control-options {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    justify-content: space-between;
}

.form-control-options-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    i {
        font-size: 16px;
        padding-right: 10px;
    }
}

.form-control-options-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    i {
        font-size: 16px;
        padding-right: 10px;
    }
}

.form-control-options-option {
    padding-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .form-item-actions {
        width: 100%;
        max-width: 180px;

        .dropdown-menu {
            background-color: #001233;

            .dropdown-menu-item:hover {
                background-color: #33466b;
            }
        }

        .dropdown-menu-button.icon {
            color: white;
            background-color: var(--arxs-icon-color);
            border: 1px solid var(--arxs-icon-border-color);
            border-radius: 4px;
        }

        .dropdown-menu-item-inner {
            .title {
                font-size: 12px;
                font-weight: normal;
            }
        }
    }
}
