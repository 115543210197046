.recipients {
  display: flex;
  align-items: center;
  background-color: var(--arxs-input-background-color);
  border: 1px solid var(--arxs-input-border-color);
  gap: 5px;
  padding: 0 5px;
  height: 29px;

  .recipient {
    border-radius: 15px;
    color: #fff;
    background-color: var(--arxs-icon-color);
    padding: 5px 10px;

    i.fa-times {
      cursor: pointer;
    }
  }

  button {
    padding: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
