.checklists {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .checklists-action-add {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #acbcd9;
    border-radius: 5px;
    background-color: var(--arxs-border-shadow-color);
    height: 60px;
    cursor: pointer;
    color: var(--arxs-secondary-color);
    font-weight: bold;
    font-size: 12px;

    i {
      margin-left: 5px;
    }
  }
}
