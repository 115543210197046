.notification-tile {
    position: relative;
    height: 92px;
    width: 188px;
    border-radius: 5px;
    color: white;
    background: linear-gradient(180deg, #4D79FF 0%, #4D56FF 100%);
    box-shadow: 0 2px 4px 0 rgba(0, 27, 77, 0.1);
    padding: 11px 15px 13px 11px;

    h2 {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
    }

    >div {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        h1 {
            font-size: 38px;
            font-weight: 500;
            letter-spacing: 0;
            text-shadow: 0 0 10px #FFFFFF;
            padding: 0;
        }

        i {
            width: 32px;
            height: 32px;
            display: block;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            color: #4D56FF;
            background: white;
            border-radius: 50%;
            margin-top: 9px;
        }
    }

    &:hover {
        border: 1px solid var(--arxs-status-active-color);
        box-shadow: 0 0 10px 0 rgba(77, 121, 255, 0.7);

        .tile-detail {
            visibility: visible;
            opacity: 1;
            display: block;
        }
    }

    .tile-detail {
        position: absolute;
        top: 0;
        left: 30px;
        top: 20px;
        padding: 15px;
        user-select: none;
        border-radius: 5px;

        z-index: 999;

        transition: all 0.5s ease;
        visibility: hidden;
        opacity: 0;
        display: none;
        overflow: auto;

        color: var(--arxs-secondary-color);
        background-color: var(--arxs-list-card-background-color);

        .tile-detail-item {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            .tile-detail-item-bullet {
                font-size: 30px;
                line-height: 10px;
                margin-right: 5px;
            }

            .tile-detail-title {
                padding: 5px 0;
                white-space: nowrap;
            }

            .badge {
                margin-left: 5px;
            }

            &:hover {
                opacity: 1;
                box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
            }
        }

        &:hover {
            opacity: 1;
            box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
        }
    }
}