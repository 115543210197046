.option-popup-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--arxs-popup-backdrop-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 201;

    .option-popup {
        background-color: var(--arxs-nav-background-color);
        box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
        border-radius: 5px;
        padding: 20px;
        position: relative;

        display: flex;
        flex-direction: column;

        overflow: hidden;

        h1 {
            margin-top: 10px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            flex-basis: 19px;
        }

        .option-popup-close {
            position: absolute;
            right: 14px;
            top: 14px;
            cursor: pointer;
        }

        .option-popup-body {
            margin-top: 25px;
            
            flex-grow: 1;
            overflow: auto;

            .option-options {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .button {
                    margin-right: 15px;
                    margin-left: 15px;
                    margin-top: 1px;
                    margin-bottom: 1px;
                    ;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .option-popup-backdrop {
        .option-popup {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1224px) {
    .option-popup-backdrop {
        .option-popup {
            position: relative;
            flex-basis: 20%;
            min-width: 320px;
        }
    }
}
