.amend-actualduration-tasklike-popup {
  background-color: var(--arxs-nav-background-color);
  box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
  border-radius: 5px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: 20%;
  min-width: 320px;

  h1 {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }

  .amend-actualduration-tasklike-popup-close {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }

  .amend-actualduration-tasklike-popup-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .row {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .field {
        flex-grow: 1;

        .k-widget {
          flex-grow: 1;
        }
      }
    }

    .buttons {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      position: sticky;
      bottom: 10px;

      .button {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
