.form-control {
    .form-control-field {
        .icons {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-bottom: 5px;

            .icon-row {
                display: flex;
                flex-direction: row;
                gap: 5px;
                padding-bottom: 5px;
                flex-wrap: wrap;
            }
        }
    }
}