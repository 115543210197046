.kanban-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
  
    .kanban-buckets {
        display:flex;
        flex-direction: row;
        height: 100%;
    }
}