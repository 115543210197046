.grid-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  .grid-toolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }

  .grid-body {
    flex: 1 1 auto;
    overflow: hidden;
  }

  .k-grid {
    color: var(--arxs-grid-color);
    background: var(--arxs-grid-background);
    border-color: var(--arxs-grid-border-color);
    z-index: 2;

    td,
    .k-grid-content,
    .k-grid-header-locked,
    .k-grid-content-locked {
      border-color: var(--arxs-grid-border-color);
    }

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
      border-left: 1px solid var(--arxs-grid-border-color);
    }

    td,
    center {
      text-align: center;

      i {
        font-size: 14px;
      }
    }

    th {
      font-weight: 600;
      border-left: 1px solid var(--arxs-grid-border-color);

      &.k-header.active .k-grid-column-menu {
        color: #fff;
        background-color: #ff6358;
      }

      i {
        width: 100%;
        text-align: center;
      }

      .icon-image {
        height: 18px;
        width: 18px;
      }
    }

    tbody {

      tr:hover,
      tr.k-state-hover {
        color: var(--arxs-grid-color);
      }

      .nav-details-pane {
        color: #4d79fd;
        font-size: 12px;
        font-weight: bold;
        //text-decoration: underline;
      }

      .nav-details-pane:hover {
        cursor: pointer;
      }
    }

    .k-grouping-row,
    .k-grouping-header,
    .k-group-cell,
    .k-group-indicator {
      border-color: var(--arxs-grid-border-color);
      color: var(--arxs-grid-header-color);
      background: var(--arxs-grid-background);
    }

    .k-grid-header {
      border-color: var(--arxs-grid-border-color);
      color: var(--arxs-grid-header-color);
      background: var(--arxs-grid-header-background);

      .k-table, .k-data-table, .k-table-thead {
        border-color: var(--arxs-grid-border-color);
        color: var(--arxs-grid-header-color);
        background: var(--arxs-grid-header-background);
      }
    }

    .k-grid-container {
      border-color: var(--arxs-grid-border-color);
      color: var(--arxs-grid-color);
      background: var(--arxs-grid-background);
      
      .k-table, .k-data-table {
        border-color: var(--arxs-grid-border-color);
        color: var(--arxs-grid-color);
        background: var(--arxs-grid-background);
      }

      .k-alt {
        color: var(--arxs-grid-alt-color);
        background: var(--arxs-grid-alt-background);
      }
    }
  }
}

.icon-list {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  gap: 2px;

  .icon-image {
    flex-basis: 28px;
    width: 28px;
    height: 28px;
  }
}

.k-filter-menu-container {
  .k-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 0.5rem 1.5rem 0;
    width: calc(100% - 3rem);
    box-sizing: border-box;
    border: 1px solid var(--arxs-input-border-color);

    &.k-filter-and {
      width: initial;
    }

    .k-input-inner {
      margin: 5px;
      width: 100%;
    }
  }

  > .k-input {
    margin: 0.5rem 1.5rem 0;
    padding: 0.375em 0 .375em 0;
    width: calc(100% - 3rem);
    box-sizing: border-box;
    border: 1px solid var(--arxs-input-border-color);

    .k-input {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.375em 0 0.375em;
    }
  }
}