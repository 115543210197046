.tree-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .k-treeview {
    color: var(--arxs-primary-color);
  }

  .k-treeview-leaf-text {
      > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          font-size: 14px;

          > i {
              color: var(--arxs-icon-color);
          }
      }
  }

  .center {
    text-align: center;
  }
}