.icon-image {
    // flex-basis: 87px;
    // width: 87px;
    // height: 87px;
    border-radius: 2px;

    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.icon-image.selected {
    border: 2px solid var(--arxs-status-active-color);
    padding: 2px;
    box-shadow: 3px 3px 10px 0 rgba(77, 121, 255, 0.7);
}
