.plannable-items {
  display: flex;
  flex-direction: column;
  height: 100%;

  background-color: var(--arxs-input-background-color);

  overflow: hidden;

  .plannable-items-filters {
    flex: 0 0 43px;
    display: flex;
    user-select: none;

    .input-wrapper {
      position: relative;
      flex-grow: 1;
      padding: 1px;
      margin-left: 5px;
      border-right: 1px solid var(--arxs-border-shadow-color);

      input {
        width: calc(100% - 43px);
        margin-left: 43px;
        border: none;
        height: 100%;
      }

      &::before {
        position: absolute;
        top: 16px;
        left: 21px;
      }
    }
    
    .structure-filter {
      .structure-filter-header {
        height: 100%;
      }
    }

    .sortkindtype-filter {
      .sortkindtype-filter-header {
        height: 100%;
      }
    }

    .multiselect {
      flex: 0 0 200px;
      height: 43px;

      .k-multiselect-wrap {
        display: flex;
        align-items: center;

        border-top: none;
        border-right: none;
        border-radius: 0;
        border-color: var(--arxs-grid-border-color);

        li {
          color: var(--arxs-primary-color);
          background-color: var(--arxs-input-background-color);
          border: none;
        }

        .k-clear-value {
          display: none;
        }
      }
    }
  }

  .folder-button-strip {
    padding: 10px;

    flex: 0 0 50px;
    border-top: 1px solid var(--arxs-nav-border-color);
    border-bottom: 1px solid var(--arxs-nav-border-color);
    font-weight: bold;
    list-style-type: none;
    user-select: none;
    display: flex;
    font-size: 14px;

    .folder-button {
      border: none;
      background: none;

      padding: 0 12px 12px 12px;
      color: var(--arxs-primary-inactive-color);
      cursor: pointer;
      height: 30px;

      &::before {
        content: none;
      }

      &.active {
        color: var(--arxs-primary-color);
        font-weight: bold;
        border-bottom: 3px solid var(--arxs-tab-border-color);
      }

      .badge {
        margin-left: 5px;
        margin-bottom: 3px;
      }
    }
  }

  .card-list {
    flex: 1 1;
    overflow: auto;

    .card-list-content {
      padding: 10px;
    }

    > label {
      font-weight: bold;
    }
  }
}