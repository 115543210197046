.item-list {
    width: 100%;
    margin-top: 10px;

    .item-list-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
        gap: 5px;

        .header {
            display: flex;

            h4 {
                color: var(--arxs-primary-inactive-color);
                font-size: 12px;
                flex: 1 0;

                span {
                    color: var(--arxs-badge-accent-background-color);
                }
            }
        }

        .row {
            display: flex;
            color: var(--arxs-primary-color);
            gap: var(--arxs-field-gap);

            .item-list-required {
                span {
                    color: var(--arxs-badge-accent-background-color);
                }
            }

            .card-list.field {
                .card-list-actions {
                    .selection-label {
                        padding-top: 0px;

                        .selection-label-action {
                            width: 310px;
                        }
                    }
                }

                .card-list-content {
                    .card-list-content-cards {
                        padding-top: 0px;
                    }
                }
            }
        }

        .item-list-table-action {
            text-align: right;
            width: 18px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;

            i {
                font-size: 16px;
                color: var(--arxs-icon-inactive-color);

                &:hover {
                    color: var(--arxs-secondary-color);
                }
            }
        }
    }
}
