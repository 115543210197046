.form-control.instruction { 
    overflow: hidden;
    font-size: 12px;

    h1, h2, h3, h4, h5, h6 {
        color: var(--arxs-grey-base) !important;
    }
    ol, ul {
        margin-inline-start: 2em;
    }
    ul > li {
        list-style-type: initial;
    }
}