.risk-matrix {
    display: flex;
    justify-content: center;

    .risk-matrix-left {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 30px;
        padding-top: 100px;
        font-weight: bold;

        .text {
            transform: rotate(-90deg);
        }
    }

    .risk-matrix-inner {
        display: flex;
        flex-direction: column;

        .risk-matrix-top {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            padding-left: 70px;
            font-weight: bold;
        }

        .risk-matrix-row {
            display: flex;
            flex-direction: row;

            .risk-matrix-cell {
                border: 1px;
                border-style: solid;
                border-color: var(--arxs-border-shadow-color);
                width: 70px;
                height: 70px;
                display: flex;
                align-items: center;

                .risk-matrix-cell-value {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .risk-matrix-cell.no-border {
                border: 0px;
                border-style: none;
            }

            .risk-matrix-cell.header {
                font-size: xx-small;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.risk-matrix-cell.status-completed {
    color: var(--arxs-status-completed-color);
    background-color: var(--arxs-status-completed-color-background);
    &:hover {
        color: var(--arxs-primary-color);
        background-color: var(--arxs-status-completed-color);
    }
}

.risk-matrix-cell.status-warning {
    color: var(--arxs-status-warning-color);
    background-color: var(--arxs-status-warning-color-background);
    &:hover {
        color: var(--arxs-primary-color);
        background-color: var(--arxs-status-warning-color);
    }
}

.risk-matrix-cell.status-severe {
    color: var(--arxs-status-severe-color);
    background-color: var(--arxs-status-severe-color-background);
    &:hover {
        color: var(--arxs-primary-color);
        background-color: var(--arxs-status-severe-color);
    }
}

.risk-matrix-cell.status-error {
    color: var(--arxs-status-error-color);
    background-color: var(--arxs-status-error-color-background);
    &:hover {
        color: var(--arxs-primary-color);
        background-color: var(--arxs-status-error-color);
    }
}

.risk-matrix-cell.status-completed.selected {
    color: var(--arxs-primary-color);
    background-color: var(--arxs-status-completed-color);
}

.risk-matrix-cell.status-warning.selected {
    color: var(--arxs-primary-color);
    background-color: var(--arxs-status-warning-color);
}

.risk-matrix-cell.status-severe.selected {
    color: var(--arxs-primary-color);
    background-color: var(--arxs-status-severe-color);
}

.risk-matrix-cell.status-error.selected {
    color: var(--arxs-primary-color);
    background-color: var(--arxs-status-error-color);
}


@media only screen and (max-height: 800px) {
    .risk-matrix {
        .risk-matrix-left {
            width: 20px;
            padding-top: 70px;
        }
    
        .risk-matrix-inner {
            display: flex;
            flex-direction: column;
    
            .risk-matrix-top {
                height: 20px;
                padding-left: 50px;
            }
    
            .risk-matrix-row {
    
                .risk-matrix-cell {
                    width: 50px;
                    height: 50px;
                }

                .risk-matrix-cell.header {
                    font-size: xx-small;
                    word-break: break-all;
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-height: 630px) {
    .risk-matrix {
        .risk-matrix-left {
            width: 20px;
            padding-top: 60px;
        }
    
        .risk-matrix-inner {
            display: flex;
            flex-direction: column;
    
            .risk-matrix-top {
                height: 20px;
                padding-left: 40px;
            }
    
            .risk-matrix-row {
    
                .risk-matrix-cell {
                    width: 40px;
                    height: 40px;
                }

                .risk-matrix-cell.header {
                    font-size: xx-small;
                    word-break: break-all;
                    text-align: center;
                }
            }
        }
    }
}
