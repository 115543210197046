.gantt-container {
  // flex: 1 0;
  overflow: hidden;
  height: 100%;

  .b-container {
    height: 100%;
  }

  .b-nonworkingtime {
    background: repeating-linear-gradient(
      45deg,
      rgba(188, 96, 96, 0.05),
      rgba(188, 96, 96, 0.05) 10px,
      rgba(152, 70, 70, 0.15) 10px,
      rgba(152, 70, 70, 0.15) 20px
    );
  }

  .gantt-task-card-container {
    display: flex;
  }

  .b-ganttbase {
    color: var(--arxs-secondary-color);
    background-color: var(--arxs-background-color);

    .b-gantt-body-wrap {
      .b-grid-panel-body {
        background-color: var(--arxs-background-color);

        .b-yscroll-pad {
          background-color: var(--arxs-background-color);
          border-left: 1px solid var(--arxs-background-color);
        }

        .b-grid-header-container {
          border-bottom: inherit;

          .b-header {
            .b-grid-headers {
              .b-grid-header {
                .b-widget {
                  .b-sch-header-row {
                    .b-sch-header-timeaxis-cell:hover {
                      background-color: var(--arxs-background-color);
                    }
                  }
                }
                .b-widget:hover {
                  background-color: var(--arxs-background-color);
                }
              }
            }
          }

          .b-grid-header.b-level-0.b-depth-0 {
            color: var(--arxs-secondary-color);
            background-color: var(--arxs-background-color);

            border-color: var(--arxs-nav-border-color);
          }

          .b-sch-timeaxiscolumn.b-depth-0:hover {
            background-color: var(--arxs-background-color) !important;
          }

          .b-sch-header-timeaxis-cell {
            color: var(--arxs-secondary-color);
          }

          .b-horizontaltimeaxis {
            .b-sch-header-timeaxis-cell {
              border-color: var(--arxs-nav-border-color);
            }
          }
        }

        .b-grid-splitter,
        .b-grid-splitter.b-hover {
          background: var(--arxs-nav-dropdown-background-color);

          .b-grid-splitter-inner {
            background: var(--arxs-nav-dropdown-background-color);
          }
        }
      }
    }

    .b-column-line {
      border-color: var(--arxs-nav-border-color);
    }

    .b-grid-row {
      background-color: var(--arxs-background-color);
      border-color: var(--arxs-nav-border-color);

      .b-grid-cell {
        color: inherit;
        border-color: var(--arxs-nav-border-color);

        .b-tree-cell {
          .b-tree-cell-inner {
            .b-tree-cell-value {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
