.report-module-config {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  .field-selection {
    padding-top: 10px;
    flex-basis: calc(50% - 20px) !important;
    @media only screen and (max-width: 500px) {
      flex-basis: 100% !important;
    }
    justify-content: flex-start;

    > label {
      display: block;
      font-size: 14px;
      color: var(--arxs-wizard-label-color);
      margin-bottom: 10px
    }

    .dropdown {
      margin-bottom: 20px;
    }

    .multiselect {
      width: 100%;
      height: auto;
      .k-input-values {
        height: auto;
        .k-chip-list {
          display: flex;
          height: auto;
          flex-wrap: wrap;
        }
      }
    }
  }
}
