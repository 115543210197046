.home {
  display: flex;
  width: 100%;
  height: 100%;

  .home-left {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .home-right {
    display: flex;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 10px;

    .buttons {
      width: 200px;

      button.create {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 92px;
        width: 188px;
        min-width: 188px;
        gap: 10px;
        text-shadow: 0 0 10px #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 27, 77, 0.1);

        i {
          font-size: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1223px) {
  .home {
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: 10px;

    .home-left {
      height: 100%;
      flex-grow: 1;
    }

    .home-right {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow-x: auto;
      width: 100%;
      height: 100%;
      max-height: 120px;
      margin-bottom: 10px;

      .buttons {
        display: flex;
        justify-content: center;
        width: 188px;
      }

      .notification-tiles {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1223px) {
  .home {
    flex-direction: row;
    gap: 20px;

    .home-left {
      gap: 10px;
      flex: 1 0;
    }

    .home-right {
      display: flex;
      flex-direction: row;
      flex-basis: 210px;
      align-content: flex-start;
      align-items: flex-start;

      .buttons {
        display: flex;
      }
    }
  }
}