.userprofile {
    display: flex;
    flex-direction: column;
    font-family: var(--arxs-nav-title-font-family, "Roboto Condensed", sans-serif);

    .userprofile-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 61px;
        padding-bottom: 20px;

        .userprofile-title {
            font-size: 22px;
            font-weight: bold;
            line-height: 25px;
        }
    }

    .userprofile-details {
        display: flex;
        flex-direction: row;
        border-radius: 10px;
        border: 1px solid var(--arxs-wizard-border-color);
        height: 100%;
        margin-bottom: 30px;
        background-color: var(--arxs-wizard-background);

        .userprofile-details-nav {
            display: flex;
            flex-direction: column;
            width: 200px;
            border-right: 1px solid var(--arxs-wizard-border-color);

            .userprofile-details-nav-id {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .userprofile-details-nav-id-avatar {
                    margin-top: 40px;
                    margin-left: 10px;
                    margin-right: 10px;

                    .avatar {
                        height: 100px;
                        width: 100px;
                    }

                    .userprofile-details-nav-id-avatar-controls {
                        width: 100%;
                        display: flex;
                        flex-direction: row-reverse;

                        .userprofile-details-nav-id-avatar-control {
                            padding: 2px 2px 2px 2px;

                            &:hover {
                                cursor: pointer;
                            }

                            i {
                                font-size: 14px;
                                color: var(--arxs-icon-inactive-color);

                                &:hover {
                                    color: var(--arxs-icon-color);
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .userprofile-details-nav-id-name {
                    margin-top: 15px;
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
            }

            .userprofile-details-nav-items {
                margin-top: 30px;
                margin-left: 30px;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: var(--arxs-primary-inactive-color);

                .userprofile-details-nav-items-item {
                    padding-bottom: 30px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .userprofile-details-nav-items-item.active {
                    padding-bottom: 30px;
                    color: var(--arxs-primary-color);

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .userprofile-details-content {
            width: 100%;
            padding: 40px 10px;

            label {
                color: var(--arxs-wizard-label-color);
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 10px;
                width: 100%;

                span {
                    color: var(--arxs-badge-accent-background-color);
                }
            }

            .userprofile-details-content-api {
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .userprofile-details-content-api-grid {
                    width: auto;

                    >div {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        i {
                            font-size: 14px;
                            color: var(--arxs-icon-inactive-color);

                            &:hover {
                                font-size: 14px;
                                color: var(--arxs-icon-color);
                                cursor: pointer;
                            }
                        }
                    }
                }

                .userprofile-details-content-api-actions {
                    margin-top: 20px;
                }
            }

            .userprofile-details-content-account {
                display: flex;
                flex-direction: column;
                width: 250px;
                gap: 20px;

                @media only screen and (max-width: 500px) {
                    width: 100%;
                }

                .label {
                    width: 100%;
                }

                .value {
                    width: calc(100% - 20px);
                }

                .userprofile-details-content-account-email {
                    display: flex;
                    flex-direction: column;
                }

                .userprofile-details-content-account-phone {
                    display: flex;
                    flex-direction: column;
                }

                .userprofile-details-content-account-notifications {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;

                    .value {
                        margin-left: 15px;
                        min-width: 20px;
                    }
                }

                .userprofile-details-content-account-language {
                    .dropdown {
                        width: 100%;
                    }
                }

                .userprofile-details-content-account-signature {
                    display: flex;
                    flex-direction: column;

                    .userprofile-details-content-account-signature-value {
                        display: flex;
                        flex-direction: column;

                        .signature {
                            width: 230px;
                            height: 115px;
                        }

                        .userprofile-details-content-account-signature-controls {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: flex-end;
                            width: 100%;
                            max-width: 230px;
                            padding-top: 5px;

                            .userprofile-details-content-signature-control {
                                padding: 2px 2px 2px 2px;

                                &:hover {
                                    cursor: pointer;
                                }

                                i {
                                    font-size: 14px;
                                    color: var(--arxs-icon-inactive-color);

                                    &:hover {
                                        color: var(--arxs-icon-color);
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }

                .userprofile-details-content-account-actions {
                }
            }
        }
    }
}