.form-control {
    .form-control-field {
        &.risk {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            // grid-gap: 5px;
    
            i {
                font-size: 24px;
                color: var(--arxs-status-active-color);
                cursor: pointer;
            }
    
            i.fas.status-completed {
                color: var(--arxs-status-completed-color);
            }
    
            i.fas.status-active {
                color: var(--arxs-status-active-color);
            }
    
            i.fas.status-warning {
                color: var(--arxs-status-warning-color);
            }
    
            i.fas.status-severe {
                color: var(--arxs-status-severe-color);
            }
    
            i.fas.status-error {
                color: var(--arxs-status-error-color);
            }
    
            i.fas.fa-thumbs-up.status-active {
                rotate: -45deg;
            }
    
            i.fas.fa-thumbs-up.status-warning {
                rotate: -90deg;
            }
    
            i.fas.fa-thumbs-up.status-severe {
                rotate: -135deg;
            }
    
            i.fas.fa-thumbs-up.status-error {
                rotate: 180deg;
            }
    
            .form-control-field-action {
                i {
                    color: var(--arxs-icon-inactive-color);
                    font-size: 16px;
    
                    &:hover {
                        color: var(--arxs-icon-color);
                    }
                }
            }
        }
    }
}