.wizard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 26px;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: var(--arxs-secondary-color);
    border: 1px solid var(--arxs-border-shadow-color);
    background-color: var(--arxs-border-shadow-color);
  }

  .title {
    display: flex;
    margin-left: 15px;
  }

  .wizard-body {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .wizard-header {
      >h1 {
        margin-bottom: 10px;
      }

      >h3 {
        font-size: 14px;
        color: var(--arxs-secondary-color);
        margin-bottom: 30px;
      }

      >.separator {
        margin-bottom: 20px;
      }
    }
  }

  .validation-summary {
    background-color: var(--arxs-wizard-validation-background-color);
    border-radius: 10px;
    border: 1px solid var(--arxs-wizard-validation-border-color);
    padding: 18px;
    margin-bottom: 20px;
  }

  .wizard-buttons {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    display: flex;
    width: 90px;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    user-select: none;
    gap: 10px;
    align-self: flex-end;

    @media only screen and (max-width: 500px) {
      right: 0;
    }

    .wizard-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;

      i {
        font-size: 20px;
      }

      &.wizard-cancel {
        background-color: var(--arxs-secondary-color);
      }
    }
  }

  .steps {
    flex-grow: 1;
    margin-bottom: 30px;

    .step {
      color: var(--arxs-secondary-color);
      background-color: var(--arxs-wizard-background);
      border-radius: 10px;
      border: 1px solid var(--arxs-wizard-border-color);

      &~.step {
        margin-top: 30px;
      }

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px;
        justify-content: left;

        .title {
          font-size: 18px;
        }
      }

      .body {
        width: 100%;
        padding: 10px 30px;
        color: var(--arxs-grey-base);
        display: flex;
        flex-direction: column;
        gap: var(--arxs-field-gap);

        .row {
          width: 100%;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--arxs-field-gap);
          align-items: flex-start;
        }

        // .row~.row {
        //   h3 {
        //     font-size: 14px;
        //     margin-top: 25px;
        //     color: var(--arxs-secondary-color);
        //   }
        // }

        .item-list {
          .row {
            margin: 5px 0;
          }

          .header {
            gap: var(--arxs-field-gap);
          }
        }

        .field {
          flex: 1 0;
          max-width: 50%;

          // Nested fields like those in an itemlist should not be half width
          .field {
            max-width: initial;
          }

          label {
            display: block;
            font-size: 14px;
            color: var(--arxs-wizard-label-color);
          }

          textarea {
            height: 27px;
          }

          .k-combobox,
          .k-widget:not(.k-switch) {
            width: 100%;
          }

          .k-widget.k-editor {
            margin-top: 10px;
          }
        }

        .item-list-table {
          .header {
            padding: 0;
          }
        }
      }

      .Collapsible__trigger {
        display: flex;
        position: relative;
        cursor: pointer;
        // border-bottom: 1px solid var(--arxs-nav-border-color);
        color: var(--arxs-wizard-header-color);
        align-items: center;

        &:after {
          position: absolute;
          top: calc(50% - 5px);
          right: 28px;

          font-family: "Font Awesome 6 Pro";
          font-size: 10px;
          font-weight: 400;
          content: "\f078";
          transition: 200ms ease-in-out;

          color: var(--arxs-wizard-header-color);
        }
      }

      .Collapsible__trigger:hover {
        &:after {
          color: var(--arxs-icon-color);
        }
      }

      .Collapsible__trigger.is-closed {
        &:after {
          transform: rotate(-180deg);
        }
      }

      .Collapsible__contentOuter {
        width: 100%;

        .Collapsible__contentInner {
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .body {
            .row {
              .card-list {
                .card-list-content {
                  .card-list-content-cards {
                    .list-card-wrapper {
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .step.open {
      .Collapsible__trigger {
        border-bottom: 1px solid var(--arxs-nav-border-color);
      }
    }
  }

  @media only screen and (max-width: 1224px) {
    .steps {
      .step {
        .body {
          padding: 10px 15px;

          .row {
            .field {
              flex: 1 0 100%;
              max-width: initial;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1224px) {
    .validation-summary {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 0;
      right: 0;
      flex-basis: 225px;
      margin-left: 40px;
    }

    .steps {
      .step {
        .body {
          padding: 10px 15px;

          .row {
            .Collapsible__contentOuter {
              width: 100%;

              .Collapsible__contentInner {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .document_type {
                  .document-content {
                    .document-label {
                      min-width: 310px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}