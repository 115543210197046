.route-restricted {
  display: flex;
  flex-direction: column;
  height: 100%;

  .route-restricted-title {
    font-size: 22px;
    font-weight: bold;
    line-height: 25px;
  }
  .route-restricted-splash {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 30px;

    img {
      height: 50%;
      border-radius: 5px;
      box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.2);
    }
    span {
      font-size: 14px;
    }
  }
}