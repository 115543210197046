.board-wrapper {
  height: 100%;

  .board {
    height: 100%;
    user-select: none;
    white-space: nowrap;
    position: relative;

    .board-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 36px;
      align-items: center;

      .board-header-title {
        font-size: 22px;
        font-weight: bold;
        line-height: 25px;

        .badge {
          margin-left: 5px;
        }

        .module-settings {
          font-size: 16px;
          margin-left: 10px;
          cursor: pointer;
          color: var(--arxs-secondary-color);

          &:hover {
            color: var(--arxs-icon-color);
          }
        }

        .arxs-library {
          font-size: 16px;
          margin-left: 10px;
          cursor: pointer;
          color: var(--arxs-secondary-color);

          &:hover {
            color: var(--arxs-icon-color);
          }
        }

        button {
          margin-left: 10px;

          .board-export-selector {
            i {
              margin-right: 10px;
            }
          }
        }
      }

      .board-header-toolbar {
        flex: 0 1 665px;
        display: flex;

        .board-header-toolbar-search {
          display: flex;
          flex-grow: 1;

          .board-header-toolbar-icon {
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: 22px;
            height: 32px;
            margin-right: 10px;
          }

          .input-wrapper {
            flex: 1 1;
            position: relative;

            .fa-search::before {
              position: absolute;
              top: 10px;
              left: 5px;
            }

            input {
              height: 32px;
              width: 100%;
              border: 1px solid var(--arxs-border-shadow-color);
              border-radius: 5px;
              text-indent: 20px;
            }
          }
        }

        .board-header-toolbar-actions {
          display: flex;
          justify-content: flex-end;

          .board-header-toolbar-toggle {
            display: flex;
          }
        }
      }
    }

    .board-toolbar {
      display: flex;
      width: 100%;
      height: 42px;
      //justify-content: space-between;
      justify-content: flex-end;
      position: relative;

      .board-toolbar-views {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 36px;

        .view-dropdown {
          margin-right: 10px;
        }

        .button.reset{
          >span{
            margin-left: 10px;
          }
        }
      }

      .save {
        margin-right: 10px;
      }

      .board-toolbar-cog {
        font-size: 16px;
        padding: 7px 6px;

        .board-item-selector {
          display: flex;
          font-size: 12px;
          padding: 0 10px 0 10px;

          .checkbox {
            margin-right: 10px;
          }
        }

        &:hover {
          color: var(--arxs-grid-color);
        }
      }
    }

    .board-scroller {
      width: 100%;
      height: calc(100% - var(--arxs-views-header-height) - 1px);
      overflow-x: auto;
      overflow-y: hidden;

      .board-body {
        display: flex;
        height: 100%;
        gap: 30px;
      }
    }

    .board-action-toolbar {
      position: fixed;
      display: flex;
      align-items: center;

      bottom: 0px;
      left: 50%;
      height: 60px;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      opacity: 0.9;
      background-color: #001233;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

      color: white;

      padding: 10px;
      z-index: 3;

      .section {
        margin-right: 10px;

        >i.fa-times {
          margin-left: 15px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .badge {
        margin-right: 5px;
      }

      .badge,
      button {
        color: #fff;
        border: 1px solid #99bcff33;
        background-color: #99bcff33;
      }

      .action {
        color: #fff;

        >span {
          border-right: 1px solid #ffffff50;
          padding-right: 5px;
        }

        .dropdown-menu {
          color: #fff;
          background-color: #001233;

          li {
            .dropdown-menu-item:hover {
              background-color: #99bcff33;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1224px) {
  .board-wrapper {
    .board {
      display: flex;
      flex-direction: column;

      .board-header {
        height: auto;

        .board-header-toolbar {
          flex-direction: row;
          flex: 0 1 100%;
        }

        .board-header-toolbar-actions {
          display: flex;
          margin-left: 10px;
          justify-content: flex-end;
        }
      }

      .board-toolbar {
        height: auto;
        margin: 10px 0;

        .board-toolbar-views {
          margin-top: 0;
        }
      }

      .board-scroller {
        flex-grow: 1;
      }
    }
  }
}