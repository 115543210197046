.sortkindtype-filter {
  height: 100%;
  width: 200px;
  border-left: var(--arxs-nav-border);

  .sortkindtype-filter-header {
    height: 58px;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    width: 200px;

    i {
      padding-left: 5px;
    }
  }

  .sortkindtype-filter-dropdown {
    flex-direction: column;
    z-index: 99;
    position: absolute;
    width: 200px;
  }
}
