.scan-qr {
    padding: 0 !important;
    position: fixed;
    width: 100%;
    top: var(--arxs-nav-header-height);
    height: calc(100% - var(--arxs-nav-header-height));
    background-color: var(--arxs-background3-color);
    z-index: 99;

    button {
        position: absolute;
        top: 10px;
        padding: 7px 7px;
        width: 32px;
        height: 32px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 50%;
        border: 1px solid var(--arxs-icon-color);
        background-color: var(--arxs-icon-color);
        z-index: 100;

        i {
            font-size: 16px !important;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

button.button-scan-qr {
    flex-basis: 57px;
    height: 100%;
    background-color: var(--arxs-icon-color);
    border: none;

    i {
        color: white;
    }
}