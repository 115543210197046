.form-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 26px;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: var(--arxs-secondary-color);
    border: 1px solid var(--arxs-border-shadow-color);
    background-color: var(--arxs-border-shadow-color);
  }

  .title {
    display: flex;
    margin-left: 15px;
    font-weight: bold;
    font-size: 16px;
  }

  .form-editor-body {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 30px;

    .form-editor-header {
      > h1 {
        margin-bottom: 10px;
      }

      > h3 {
        font-size: 14px;
        color: var(--arxs-secondary-color);
        margin-bottom: 30px;
      }

      > .separator {
        margin-bottom: 20px;
      }
    }

    .form-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      .spinner {
        width: 28px;
        height: 28px;
        > div {
            border-color: var(--arxs-icon-color) transparent transparent transparent;
        }
      }
    }
  }

  .section-add {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 0 10px 0 10px;
    font-weight: 600;
    border: 1px solid var(--arxs-border-shadow-color);
    cursor: pointer;
    color: var(--arxs-icon-color);
    background-color: var(--arxs-background-color);
    gap: 5px;
  }

  .form-editor-actions {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    > div {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      border: 1px dashed #acbcd9;
      border-radius: 5px;
      background-color: var(--arxs-border-shadow-color);
      height: 60px;
      cursor: pointer;
      color: var(--arxs-secondary-color);
      font-weight: bold;
      font-size: 12px;

      > span {
        overflow: hidden;
        white-space: wrap;
        text-align: center;
        max-width: 70%;
      }
    }

    .form-editor-action-insert-existing {
      flex-grow: 1;
    }

    .form-editor-action-unlink-form {
      flex-grow: 1;
    }
    
    .form-editor-action-add-section {
      flex-grow: 1;
    }

    .form-editor-action-menu {
      flex-basis: 50px;
    }
  }

  .form-editor-is-external-message {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: var(--arxs-status-active-color);
  }

  .form-editor-read-only-message {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: var(--arxs-status-warning-color);
  }

  .sections {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;

    .section {
      color: var(--arxs-secondary-color);
      background-color: var(--arxs-form-background);
      border-radius: 10px;
      border: 1px solid var(--arxs-form-border-color);

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px;
        justify-content: left;

        .title {
          font-size: 18px;
        }
      }

      .section-body {
        width: 100%;
        color: var(--arxs-grey-base);
        display: flex;
        flex-direction: column;

        .row {
          width: 100%;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--arxs-field-gap);
          align-items: flex-start;
        }

        .row ~ .row {
          h3 {
            font-size: 14px;
            margin-top: 25px;
            color: var(--arxs-secondary-color);
          }
        }

        .item-list {
          .row {
            margin: 5px 0;
          }

          .header {
            gap: var(--arxs-field-gap);
          }
        }

        .field {
          flex: 1 0;

          label {
            display: block;
            font-size: 14px;
            color: var(--arxs-form-label-color);
          }

          textarea {
            height: 27px;
          }

          .k-combobox,
          .k-widget:not(.k-switch) {
            width: 100%;
          }

          .k-widget.k-editor {
            margin-top: 10px;
          }
        }

        .item-list-table {
          .header {
            padding: 0;
          }
        }
      }

      .Collapsible__trigger {
        display: flex;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid var(--arxs-nav-border-color);
        color: var(--arxs-form-header-color);
        align-items: center;

        &:after {
          position: absolute;
          top: calc(50% - 5px);
          right: 28px;

          font-family: "Font Awesome 6 Pro";
          font-size: 10px;
          font-weight: 400;
          content: "\f078";
          transition: 200ms ease-in-out;

          color: var(--arxs-form-header-color);
        }
      }

      .Collapsible__trigger:hover {
        &:after {
          color: var(--arxs-icon-color);
        }
      }

      .Collapsible__trigger.is-closed {
        &:after {
          transform: rotate(-180deg);
        }
      }

      .Collapsible__contentOuter {
        width: 100%;

        .Collapsible__contentInner {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 1224px) {
    .sections {
      .section {
        .body {
          padding: 10px 15px;
        }
      }
    }
  }

  @media only screen and (min-width: 1224px) {
    .validation-summary {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 0;
      right: 0;
      flex-basis: 225px;
      margin-left: 40px;
    }
  }
}
