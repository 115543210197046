.list-card-wrapper {
  .list-card {
    .list-card-right {
      .list-card-subtitle {
        color: var(--arxs-status-active-color);
        font-size: 11px;
        display: flex;
        align-items: center;
        text-align: center;
        gap: 6px;

        > div {
          display: flex;
          gap: 2px;
          align-items: center;
          text-align: center;
        }

        i {
          font-size: 10px;
        }

        .badge {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          font-size: 12px;
          border-radius: 3px;
          width: 50px;
          line-height: 20px;
          height: 20px;

          &.priority-low {
            color: var(--arxs-status-completed-color);
            background-color: var(--arxs-status-completed-color-background);
          }

          &.priority-normal {
            color: var(--arxs-status-warning-color);
            background-color: var(--arxs-status-warning-color-background);
          }

          &.priority-high {
            color: var(--arxs-status-error-color);
            background-color: var(--arxs-status-error-color-background);
          }

          &.consultancy-type-purchase {
            width: 60px;
          }
          &.consultancy-type-formal {
            width: 60px;
          }
          &.consultancy-type-general {
            width: 65px;
          }
          &.consultancy-type-generalorpurchase {
            width: 100px;
          }
        }
      }
    }
  }
}
