.switch-wrapper { 
    display: flex;
    align-items: center;
    gap: 10px;

    .k-switch-on .k-switch-track
        , .k-switch-on:focus .k-switch-track
        , .k-switch-on.k-focus .k-switch-track {
        border-color: var(--arxs-icon-color);
        background-color: var(--arxs-icon-color);
    }
}