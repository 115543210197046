.input-popup-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--arxs-popup-backdrop-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 201;

    .input-popup {
        background-color: var(--arxs-nav-background-color);
        box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
        border-radius: 5px;
        padding: 20px;
        gap: 15px;

        display: flex;
        flex-direction: column;
        position: relative;

        h1 {
            margin-top: 10px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            overflow: hidden;
        }

        .input-popup-close {
            position: absolute;
            right: 14px;
            top: 14px;
            cursor: pointer;
        }

        .input-popup-body {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .input-popup-body-content {
                display: flex;
                flex-direction: row;
                align-items: center;

                i {
                    font-size: 3em;
                    color: var(--arxs-icon-color);
                    margin-right: 15px;
                }

                .input-popup-body-content-inputs {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }


            .buttons {
                display: flex;
                justify-content: center;

                .button {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .input-popup-backdrop {
        .input-popup {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 1224px) {
    .input-popup-backdrop {
        .input-popup {
            position: relative;
            flex-basis: 20%;
            min-width: 320px;
        }
    }
}
