
.search {
    display: flex;
    flex-direction: row;
    height: 52px;
    align-items: center;
    color: var(--arxs-secondary-color);
    background-color: var(--arxs-nav-background-color);
    border: 1px solid var(--arxs-border-shadow-color);
    box-shadow: 0 -2px 10px 0 var(--arxs-border-shadow-color);
    padding-left: 21px;

    .search-input {
      width: 100%;

      padding-left: 20px;

      input {
        width: calc(100% - 20px);
        border: none;
        height: 23px;
      }

      .fa-search {
        height: 14px;
        padding-left: 18px;
      }
    }
    .search-filter {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 52px;
      height: 52px;
      background-color: var(--arxs-icon-color);

      i {
        font-size: 16px;
        color: white;
      }
    }
  }