.input-wrapper.text-input {
  flex: 1 1;
  position: relative;
  height: 32px;

  &::before {
    position: absolute;
    top: calc(50% - 6px);
    left: 10px;
    color: var(--arxs-secondary-color);
  }

  input {
    height: 100%;
    width: 100%;
    border: 1px solid var(--arxs-border-shadow-color);
    border-radius: 5px;
    text-indent: 30px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: var(--arxs-secondary-color);
    }
  }
}

.input-wrapper {
  input {
    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: var(--arxs-secondary-color);
    }
  }
}
