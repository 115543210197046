.item-selector {
  height: 100%;
  display: flex;
  flex-direction: column;

  .text-input {
    flex: 0 0 50px;
    height: 50px;
  }

  .item-list {
    flex: 1 0;

    .item-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid var(--arxs-nav-border-color);
      position: relative;
      cursor: pointer;
      
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;
      }      
    }
  }
}