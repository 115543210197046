.tagtreeview {
  height: 100%;

  .treeview {
    height:100%;
    .kendo-treeview {
      height: 100%;
      .k-treeview {
        height: 100%;
        overflow-y: auto;
        .k-treeview-lines {
          .k-treeview-item {
            .k-treeview-mid, .k-treeview-bot {
              .k-treeview-leaf {
                &:hover {
                  background-color: var(--arxs-icon-background-color);
                }

                i.fas.fa-tag {
                  color: var(--arxs-status-warning-color);
                }
              }
            }
            .k-animation-container.k-animation-container-relative {
              .k-child-animation-container {
                .k-content {
                  .k-treeview-item {
                    .k-treeview-mid {
                      .k-treeview-leaf {
                        &:hover {
                          background-color: var(--arxs-icon-background-color);
                        }
                        i.fas.fa-tag {
                          color: var(--arxs-status-warning-color);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
