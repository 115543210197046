.prompt-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--arxs-popup-backdrop-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;

  .prompt-popup {
    background-color: var(--arxs-nav-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;
    padding: 20px;

    display: flex;
    flex-direction: column;

    h1 {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
    }

    .prompt-popup-close {
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;
    }

    .prompt-popup-body {
      display: flex;
      flex-direction: column;

      .prompt-popup-input {
        margin-top: 25px;
      }

      .buttons {
        display: flex;
        justify-content: center;

        .button {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1224px) {
  .prompt-popup-backdrop {
    .prompt-popup {
      width: 100%;
      height: 100%;

      .prompt-popup-body {
        .buttons {
          position: absolute;
          bottom: 10px;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1224px) {
  .prompt-popup-backdrop {
    .prompt-popup {
      position: relative;
      flex-basis: 20%;
      min-width: 320px;

      .prompt-popup-body {
        .buttons {
          margin-top: 25px;
        }
      }
    }
  }
}