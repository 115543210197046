.radio-group {
  display: flex;
  flex-direction: column;
  gap: var(--arxs-field-gap);
  
  .radio-group-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    input[type=radio] {
      margin-right: 10px;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}