.mention-popup {
    background-color: var(--arxs-nav-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    max-width: 350px;
    position: absolute;
    top: 400px;
    left: 15px;

    .h1 {
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
    }
    .mention-close {
        position: absolute;
        right: 14px;
        top: 14px;
        cursor: pointer;
    }

    .mention-input {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        margin-top: 10px;
    }

    .mention-choice {
        .selected {
            border: 1px solid var(--arxs-status-active-color);
            box-shadow: 0 0 10px 0 rgba(77, 121, 255, 0.7);
            border-radius: 5px;
        }
    }
}
