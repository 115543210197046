label {
  color: var(--arxs-grey-base);

  span {
    color: var(--arxs-badge-accent-background-color);
  }
}

.field-not-found {
  background: red;
}

.field-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;

  //makes sure we have the same style in rendering read only as we have in the kendo rich text editor
  .markdown {
    h1 {
      display: block !important;
      font-size: 2em !important;
      margin-block-start: 0.67em !important;
      margin-block-end: 0.67em !important;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      font-weight: bold !important;
    }

    h2 {
      display: block !important;
      font-size: 1.5em !important;
      margin-block-start: 0.83em !important;
      margin-block-end: 0.83em;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      font-weight: bold !important;
    }

    h3 {
      display: block !important;
      font-size: 1.17em !important;
      margin-block-start: 1em !important;
      margin-block-end: 1em !important;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      font-weight: bold !important;
      color: var(--arxs-primary-color) !important;
    }

    h4 {
      display: block !important;
      margin-block-start: 1.33em !important;
      margin-block-end: 1.33em !important;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      font-weight: bold !important;
    }

    h5 {
      display: block !important;
      font-size: 0.83em !important;
      margin-block-start: 1.67em !important;
      margin-block-end: 1.67em !important;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      font-weight: bold !important;
    }

    h6 {
      display: block !important;
      font-size: 0.67em !important;
      margin-block-start: 2.33em !important;
      margin-block-end: 2.33em !important;
      margin-inline-start: 0 !important;
      margin-inline-end: 0 !important;
      font-weight: bold !important;
    }
  }
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .switch {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  &.full-width {
    width: 100% !important;
    max-width: 100% !important;
  }

  &.error {

    .k-combobox,
    .k-datepicker .k-picker-wrap,
    .k-timepicker .k-picker-wrap,
    .k-dateinput-wrap,
    .k-numeric-wrap,
    .k-dropdown-wrap,
    .k-multiselect-wrap,
    .k-popup,
    >textarea {
      border-color: var(--arxs-badge-accent-background-color) !important;
    }
  }
  
  .k-input {
    width: 100%;

    input {
      border: none;
    }
  }

  .fraction {
    display: flex;
    flex-direction: row;
    align-items: center;

    .k-input {
      width: 50px;
    }

    .fraction-char {
      // font-size: 20px;
      // color: var(--arxs-secondary-color);
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .date-time-picker {
    display: flex;
    flex-direction: row;
    width: fit-content;

    .k-timepicker {
      margin-left: 10px;
    }

    .date-time-picker-now{
      padding-left: 5px;
      font-size: 16px;
      cursor:pointer;
      color: var(--arxs-secondary-color);
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 1224px) {
  .field {
    .date-time-picker {
      width: 100%;
    }
  }
}