.risk-smileys {
    display: flex;
    flex-direction: row;
    font-size: 32px;
    justify-content: center;
    gap: 10px;

    .risk-smiley {
        height: 45px;
        width: 45px;
        &:hover {
            cursor: pointer;
        }
    }
}

.risk-smiley.status-completed {
    color: var(--arxs-status-completed-color-background);

    &:hover {
        color: var(--arxs-status-completed-color);
    }
}

.risk-smiley.status-active {
    color: var(--arxs-status-active-color-background);

    &:hover {
        color: var(--arxs-status-active-color);
    }
}

.risk-smiley.status-warning {
    color: var(--arxs-status-warning-color-background);

    &:hover {
        color: var(--arxs-status-warning-color);
    }
}

.risk-smiley.status-severe {
    color: var(--arxs-status-severe-color-background);

    &:hover {
        color: var(--arxs-status-severe-color);
    }
}

.risk-smiley.status-error {
    color: var(--arxs-status-error-color-background);

    &:hover {
        color: var(--arxs-status-error-color);
    }
}

.risk-smiley.status-active.selected {
    color: var(--arxs-status-active-color);
}

.risk-smiley.status-completed.selected {
    color: var(--arxs-status-completed-color);
}

.risk-smiley.status-warning.selected {
    color: var(--arxs-status-warning-color);
}

.risk-smiley.status-severe.selected {
    color: var(--arxs-status-severe-color);
}

.risk-smiley.status-error.selected {
    color: var(--arxs-status-error-color);
}
