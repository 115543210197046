.geolocation-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: var(--arxs-primary-color);
  }

  >i {
    margin-right: 10px;
  }
}