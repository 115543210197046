.publish-to-library-popup {
    background-color: var(--arxs-nav-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    position: relative;
    flex-basis: 20%;
    min-width: 320px;

    h1 {
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
    }

    .publish-to-library-popup-close {
        position: absolute;
        right: 14px;
        top: 14px;
        cursor: pointer;
    }

    .publish-to-library-popup-body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .content {
            display: flex;
            align-items: center;

            .spinner {
                > div {
                    border-color: var(--arxs-icon-color) transparent transparent transparent;
                }
            }

            .button {
                display: flex;
                align-items: center;
                gap: 10px;

                i {
                    font-size: 20px;
                }
            }

            .row {
                width: 100%;
            }
        }

        .buttons {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: 10px;
            gap: 10px;

            .button {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }
}
