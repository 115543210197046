.bulk-edit-relationships-popup {
    background-color: var(--arxs-nav-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;
    padding: 20px;
  
    display: flex;
    flex-direction: column;
    position: relative;
    flex-basis: 20%;
    min-width: 320px;

    .bulk-edit-relationships-popup-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    
        .row {
          .field {
            flex-grow: 1;
    
            .k-widget {
              flex-grow: 1;
            }
          }
        }

        .bulk-edit-relationships-popup-cancel {
            position: absolute;
            right: 14px;
            top: 14px;
            cursor: pointer;
        }

        .buttons {
            margin-top: 25px;
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: 10px;
        
            .button {
                margin-right: 15px;
        
                &:last-child {
                margin-right: 0;
                }
            }
        }
    }
}