.button {
    padding: 7px 6px;
    height: 32px;
    line-height: 12px;
    text-align: center;
    cursor: pointer;
    color: var(--arxs-primary-color);
    background-color: var(--arxs-input-background-color);
    opacity: 0.8;
    border-radius: 4px;
    border: 1px solid #99bcff33;

    &.create {
        color: #fff;
        border: 1px solid var(--arxs-create-color);
        background: linear-gradient(180deg, var(--arxs-create-color-gradient-lo), var(--arxs-create-color-gradient-hi));
    }

    &.reset {
        border: 1px solid var(--arxs-status-error-color-bakcground);
        background-color: var(--arxs-status-error-color-background);
        color: var(--arxs-status-error-color);
        font-weight: bold;
        .fa-circle-xmark {
            color: var(--arxs-secondary-color);
            background-color: var(--arxs-border-shadow-color);
            border: 1px solid var(--arxs-border-shadow-color);
            border-radius: 50%;
            margin-left: 10px;
        }
    }

    &.delete {
        color: #fff;
        border: 1px solid var(--arxs-status-error-color);
        background-color: var(--arxs-status-error-color);
    }

    &.icon {
        color: #fff;
        border: 1px solid var(--arxs-icon-color);
        background-color: var(--arxs-icon-color);
    }

    &.icon.alternative {
        color: var(--arxs-icon-color);
        border: 1px solid var(--arxs-icon-color);
        background-color: #fff;
    }

    &.icon-border {
        color: var(--arxs-icon-color);
        border: 1px solid var(--arxs-icon-color);
        background-color: #fff;
    }

    &.circle {
        border-radius: 50%;
    }

    i {
        font-size: 16px;
    }

    &[disabled] {
        opacity: 0.5;
    }

    &:hover {
        &:not([disabled]) {
            opacity: 1;
            box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
        }
    }

    .spinner {
        width: 28px;
        height: 28px;
    }
}