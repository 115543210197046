.image {
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.details-pane-header-right {
  .image {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
