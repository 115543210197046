nav {
    flex: 0 0 var(--arxs-nav-sidebar-width);
    min-height: 100%;

    display: flex;
    flex-direction: column;

    background-color: var(--arxs-nav-background-color);
    border-right: var(--arxs-nav-border);
    box-shadow: 2px 0 6px 0 var(--arxs-border-shadow-color);

    i {
        transition: all 0.5s ease;

        color: var(--arxs-icon-inactive-color);
        font-size: 19px;
    }

    li {
        display: block;
        text-decoration: none;
    }

    > li {
        float: left;
        position: relative;
    }
       
    li:hover {
        cursor: pointer;
    }

    .beamerButton {
        display: flex;

        > .beamer_icon {
            position: relative;
            margin-right:5px;
        }
    }

    @media only screen and (max-width: 500px) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 10;

        ul {
            padding: 10px 0 10px 0;

            li {
                clear: both;
                width: 100%;

                a > .tile,
                > .tile {
                    padding: 20px 0 20px 0;
                }
                
                a > .tile > .tile-inner,
                > .tile > .tile-inner {
                    display: flex;
                    font-size: 18px;

                    i {
                        padding: 0 0 0 40px;
                        width: 80px;
                    }
                    div {
                        padding: 0 15px 0 0;
                    }
                }

                ul {
                    margin-top: 20px;
                    transition: all 0.5s ease;
                    visibility: hidden;
                    opacity: 0;
                    display: none;
                    background-color: var(--arxs-background3-color);
                }
            }

            li:hover > div > ul,
            li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
            }
        }
    }

    @media only screen and (min-width: 500px) {
        height: 100%;

        ul {
            li ul {
                border: var(--arxs-nav-border);
                border-radius: 0 5px 5px 0;
                background-color: var(--arxs-nav-dropdown-background-color);
                box-shadow: 4px 4px 10px 0 var(--arxs-border-shadow-color);
        
                position: absolute;
                margin-top: -44px;
                left: 59px;
                z-index: 1001;
        
                transition: all 0.5s ease;
                visibility: hidden;
                opacity: 0;
                display: none;

                li {
                    clear: both;
                    width: 100%;

                    a > .tile,
                    > .tile {
                        padding: 10px 0 10px 0;
                    }
                    
                    a > .tile > .tile-inner,
                    > .tile > .tile-inner {
                        display: flex;

                        i {
                            padding: 0 10px 0 15px;
                            font-size: 15px;
                            flex: 0 0 46px;
                        }
                        div {
                            padding: 0 15px 0 0;
                            flex: 1;
                            text-align: left;
                        }
                    }
                }
            }
            
            li:hover > div > ul,
            li ul:hover {
                visibility: visible;
                opacity: 1;
                display: block;
            }
        }

        > ul > li > .tile,
        > ul > li > a > .tile {
            height: 54px;
            padding: 10px 0 10px 0;
            width: 59px;
            text-align: center;
        }

        > ul > li > .tile > .tile-inner,
        > ul > li > a > .tile > .tile-inner {
            display: flex;
            justify-content: center;
            height: 34px;
            padding: 8px 0 8px 0;
        }

        .tile:hover, .tile.active {
            background-color: var(--arxs-nav-dropdown-hover-color);
        }
    }
    
    .tile:hover, .tile.active {
        > .tile-inner {
            border-right: 2px solid var(--arxs-icon-color);

            > i {
                color: var(--arxs-nav-icon-color);
                text-shadow: var(--arxs-nav-dropdown-hover-shadow);

                &.far.fa-sun {
                    color: #ebc334;
                }
            }
        }
    }
}
