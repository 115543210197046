.k-treeview-item {
    .k-treeview-mid {
        width: 100%;
        .k-treeview-leaf {
            width: 100%;
            overflow: hidden;

            &:hover {
                background-color: var(--arxs-icon-background-color);
              }
        }
    }
}

.treeview-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .treeview-item-text {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        width: 100%;

        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--arxs-grid-color);
            font-size: 14px;
        }

        i {
            font-size: 16px;
            padding-right: 15px;
            color: var(--arxs-folder-blue);
        }
    }

    .treeview-item-add-input {
        width: 100%;
    }

    .treeview-item-editactions {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .edit {
            padding-left: 2px;
            i {
                font-size: 16px;
                color: var(--arxs-secondary-color);
            }
        }

        .remove {
            padding-left: 2px;
            i {
                font-size: 16px;
                color: var(--arxs-secondary-color);
            }
        }

        .import {
            padding-left: 2px;
            i {
                font-size: 16px;
                color: var(--arxs-status-active-color);
            }
        }

        .export {
            padding-left: 2px;
            i {
                font-size: 16px;
                color: var(--arxs-status-completed-color);
            }
        }
    }
}

.treeview-item-add {
    font-size: 12px;
    color: var(--arxs-icon-color);
    font-weight: bold;

    i {
        font-size: 12px;
        color: var(--arxs-icon-color);
    }
}

.treeview-item-add:hover {
    cursor: pointer;
}

.k-in.k-state-focused {
    .treeview-item-text {
        font-weight: bolder;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
