.minigrid-settings {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    color: var(--arxs-secondary-color);
    border: 1px solid var(--arxs-border-shadow-color);
    background-color: var(--arxs-border-shadow-color);
  }

  .title {
    display: flex;
    margin-left: 15px;
  }

  .minigrid-settings-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .minigrid-settings-header {
      >h1 {
        margin-bottom: 10px;
      }

      >h3 {
        font-size: 14px;
        color: var(--arxs-secondary-color);
        margin-bottom: 30px;
      }

      >.separator {
        margin-bottom: 20px;
      }
    }

    .validation-summary {
      background-color: var(--arxs-wizard-validation-background-color);
      border-radius: 10px;
      border: 1px solid var(--arxs-wizard-validation-border-color);
      padding: 18px;
      margin-bottom: 20px;
    }
  }

  .minigrid-settings-buttons {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 20px;
    right: 50px;
    display: flex;
    justify-content: flex-end;

    .minigrid-settings-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-left: 15px;
      
      border-radius: 50%;
      i {
        font-size: 20px;
      }
    }
  
    .minigrid-settings-cancel {
      color: var(--arxs-primary-color);
      background-color: var(--arxs-background-color);
    }
  }

  

  .steps {
    flex-grow: 1;
    margin-bottom: 30px;

    .step {
      color: var(--arxs-secondary-color);
      background-color: var(--arxs-wizard-background);
      border-radius: 10px;
      border: 1px solid var(--arxs-wizard-border-color);

      &~.step {
        margin-top: 30px;
      }

      .header { 
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px;

        .title {
          font-size: 18px;
        }
      }

      .body {
        width: 100%;
        padding: 10px 30px;
        color: var(--arxs-grey-base);

        .row {
          width: 100%;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 20px 0;
          gap: var(--arxs-field-gap);
        }

        .row ~ .row {
          h3 {
            font-size: 14px;
            margin-top: 25px;
            color: var(--arxs-secondary-color);
          }
        }

        .item-list {
          .row {
            margin: 5px 0;
          }

          .header {
            h4 ~ h4 {
              margin-left: 20px;
            }
          }
        }

        .field {
          flex: 1 0;

          &~.field {
            margin-left: 20px;
          }

          label {
            display: block;
            font-size: 14px;
            color: var(--arxs-wizard-label-color);
          }

          textarea {
            height: 27px;
          }

          .k-combobox
            , .k-widget:not(.k-switch) {
            width: 100%;
          }
        }

        .item-list-table {
          .header {
            padding: 0;
          }
        }
      }

      .Collapsible__trigger {
        display: block;
        position: relative;
        cursor: pointer;

        border-bottom: 1px solid var(--arxs-nav-border-color);

        color: var(--arxs-wizard-header-color);
      }
      
      .Collapsible__trigger::after {
        position: absolute;
        top: 28px;
        right: 28px;

        font-family: "Font Awesome 6 Pro";
        font-size: 10px;
        font-weight: 400;
        content: "\f078";

        color: var(--arxs-wizard-header-color);
      }

      .Collapsible__trigger:hover::after {
        color: var(--arxs-primary-color);
      }

      .Collapsible__contentOuter {
        width: 100%;

        .Collapsible__contentInner {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 1224px) {
    .steps {
      .step {
        .body {
          padding: 10px 15px;
        }
      }
    }
  }
}
