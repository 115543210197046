.form-editor {
    .sections {
        .section {
            .header {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                user-select: none;
                padding: 10px !important;

                .title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .spacer {
                    flex-grow: 1;
                }

                .header-actions {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    margin-right: 30px;
                }

                .header-action {
                    cursor: pointer;
                    color: var(--arxs-icon-inactive-color);
                    font-size: 14px;

                    &:hover {
                        color: var(--arxs-icon-color);
                    }
                }
            }
        }

        .section-body {
            user-select: none;

            .section-body-add {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 50px;
                padding: 0 10px 0 10px;
                font-weight: 600;
                border: 1px solid var(--arxs-border-shadow-color);
                cursor: pointer;
                color: var(--arxs-icon-color);
                background-color: var(--arxs-background-color);
                gap: 5px;
            }
        }
    }
}