.list-card-actions {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 0 5px;
  font-size: 12px;
  margin-left: 4px;

  .list-card-checkbox {
    margin-left: 4px;
  }

  .far.fa-external-link {
    cursor: pointer;
  }

  .k-switch {
    width: 48px;
    height: 16px;
  }
}