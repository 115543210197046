.library-item-preview {
    flex-grow: 1;

    height: 100%;
    overflow: hidden;

    h1 {
        margin-bottom: 10px;
        text-align: center;
    }

    .library-item-body-wrapper {
        overflow: auto;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: 1px solid var(--arxs-border-shadow-color);
    }

    .library-item-preview-body {
        width: 100%;
        height: 100%;
    }

    .spinner {

    }
}