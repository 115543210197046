.view-dropdown {
  .label {
    > i {
      margin-right: 10px;
    }
  }

  .dropdown-menu {
    width: 290px;
    padding: 0;

    @media only screen and (max-width: 400px) and (orientation: portrait) {
      width: 240px;
    }

    li {
      .dropdown-menu-item {
        height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--arxs-grid-header-color);

        .dropdown-menu-item-inner {
          padding-left: 20px;
          padding-right: 15px;
          position: relative;
          justify-content: space-between;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .bullet-glowing {
            position: absolute;
            top: 5px;
            left: 10px;
            width: 4px;
            height: 4px;
          }

          i {
            margin-left: 5px;
            width: initial;
            font-size: 16px;
            color: var(--arxs-secondary-color);
          }
        }
      }

      &.selected {
        .dropdown-menu-item {
          .dropdown-menu-item-inner {
            color: var(--arxs-primary-color);
            font-weight: 600;
          }
        }
      }

      &.header {
        font-weight: 600;
        color: var(--arxs-grid-header-color);
        background-color: var(--arxs-grid-header-background);

        &:hover {
          cursor: initial;
        }

        .dropdown-menu-item:hover {
          background-color: initial;

          > .dropdown-menu-item-inner {
            border-right: initial;
          }
        }
      }

      &.footer {
        border: 1px solid var(--arxs-icon-color);
        background-color: var(--arxs-icon-color);

        .dropdown-menu-item {
          color: #fff;

          > .dropdown-menu-item-inner {
            justify-content: center;

            i {
              color: inherit;
            }
          }

          &:hover {
            font-weight: 600;
            background-color: var(--arxs-icon-color);

            > .dropdown-menu-item-inner {
              border-right: initial;
            }
          }
        }
      }
    }
  }
}
