.list-wrapper {
    flex-shrink: 0;
    max-width: 330px;
    width: calc(100% - 30px);
    min-width: 290px;
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;

    .list {
        background-color: var(--arxs-list-background-color);
        width: 100%;
        height: calc(100% - 5px);
        border-radius: 15px;
        
        display: flex;
        flex-direction: column;
        position: relative;
        white-space: normal;

        .list-header {
            display: flex;
            align-items: center;
            
            padding: 20px 25px;
            height: 62px;
            font-size: 16px;
            color: var(--arxs-primary-color);
            border-bottom: 1px solid rgba(153,166,204,0.3);

            a {
                flex-grow: 1;
                display: flex;
                align-items: center;
            }

            .badge {
                margin-left: 5px;
            }
            
            .spacer {
                flex-grow: 1;
            }
        }

        .list-body {
            height: 100%;

            .list-card-wrapper {
                margin: 5px 10px 5px 10px;
            }
        }
    }
}