.tagmanagement {
  height: calc(100% - 23px);
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  margin: -30px -30px 0px -30px;

  .tagmanagement-header {
    display: flex;
    flex-direction: row;
    height: 72px !important;
    min-height: 72px;

    .tagmanagement-title {
      display: flex;
      font-size: 22px;
      font-weight: bold;
      align-items: center;
      padding-left: 30px;
      width: 343px !important;
      min-width: 343px !important;
      border-right: var(--arxs-nav-border);

      .tagmanagement-import {
        padding-left: 20px;
      }
    }

    .tagmanagement-toolbar {
      display: flex;
      position: relative;
      flex-direction: row;
      padding-right: 20px;
      gap: 10px;
      width: 100%;
      border-bottom: var(--arxs-nav-border);

      .tagmanagement-toolbar-breadcrumbs {
        font-size: 14px;
        width: 100%;
        color: var(--arxs-secondary-color);
        font-weight: bold;
        padding-left: 30px;
        display: flex;
        align-items: center;

        .selected {
          color: var(--arxs-nav-icon-color);
        }
      }

      .tagmanagement-toolbar-search {
        display: flex;
        align-items: center;
        
        .board-header-toolbar-icon {
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 22px;
          height: 32px;
          margin-right: 10px;
        }

        .input-wrapper {
          width: 250px;
          position: relative;

          .fa-search::before {
            position: absolute;
            top: 10px;
            left: 5px;
          }

          input {
            height: 32px;
            width: 100%;
            border: 1px solid var(--arxs-border-shadow-color);
            border-radius: 5px;
            text-indent: 20px;
          }
        }
      }

      .tagmanagement-content-empty-action {
        display: flex;
        align-items: center;

        .tagmanagement-content-empty-action-create {
          background-color: var(--arxs-create-color);
          height: 32px;
          border-radius: 4px;
          border: 0px;
          font-size: 12px;
          color: white;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 10px;

          i {
            padding-left: 6px;
          }
        }

        .tagmanagement-content-empty-action-create:hover {
          cursor: pointer;
        }
      }
    }
  }

  .tagmanagement-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 72px);

    .tagmanagement-tree {
      background-color: var(--arxs-background-color);
    //   border-right: var(--arxs-nav-border);
      height: 100%;
      width: 343px !important;
      min-width: 343px !important;
    }

    .tagmanagement-content {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: calc(100% + 52px);
      border-left: var(--arxs-nav-border);

      .tagmanagement-content-cards {
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }

      .tagmanagement-content-empty {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--arxs-secondary-color);

        .tagmanagement-content-empty-icon {
          i {
            font-size: 37px;
          }
        }

        .tagmanagement-content-empty-text {
          padding-top: 52px;
          padding-bottom: 52px;
          font-size: 14px;
          font-weight: bold;
        }

        .tagmanagement-content-empty-action {
          display: flex;
          align-items: center;

          .tagmanagement-content-empty-action-create {
            background-color: var(--arxs-create-color);
            height: 32px;
            border-radius: 4px;
            border: 0px;
            font-size: 12px;
            color: white;
            padding-left: 15px;
            padding-right: 15px;

            i {
              padding-left: 6px;
            }
          }

          .tagmanagement-content-empty-action-create:hover {
            cursor: pointer;
          }
        }
      }

      .card-row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 10px 15px;
        gap: 20px;

        .list-card-wrapper {
          flex: 0 0 280px;
          width: 280px;
          margin: 0;
        }
      }
    }
  }

  .tagmanagement-action-toolbar {
      position: fixed;
      display: flex;
      align-items: center;

      bottom: 0px;
      left: 50%;
      height: 60px;
      transform: translate(-50%, -50%);
      border-radius: 5px;
      opacity: 0.9;
      background-color: #001233;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

      color: white;

      padding: 10px;
      z-index: 3;

      .section {
        margin-right: 10px;

        >i.fa-times {
          margin-left: 15px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .badge {
        margin-right: 5px;
      }

      .badge,
      button {
        color: #fff;
        border: 1px solid #99bcff33;
        background-color: #99bcff33;
      }

      .action {
        color: #fff;

        >span {
          border-right: 1px solid #ffffff50;
          padding-right: 5px;
        }

        .dropdown-menu {
          color: #fff;
          background-color: #001233;

          li {
            .dropdown-menu-item:hover {
              background-color: #99bcff33;
            }
          }
        }
      }
    }
}
