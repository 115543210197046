.Toastify__toast--success {
  color: #fff;
  background-color: var(--arxs-status-completed-color) !important;
}
.Toastify__toast--warning {
  color: #fff;
  background-color: var(--arxs-status-warning-color) !important;
}
.Toastify__toast--error {
  color: #fff;
  background-color: var(--arxs-status-error-color) !important;
}
.Toastify__toast--info {
  color: #fff;
  background-color: var(--arxs-status-active-color) !important;
}
.Toastify__toast--default.custom-toast {
  color: #fff;
  border: 1px solid var(--arxs-icon-color);
  background-color: var(--arxs-icon-color);

  .button {
    margin-left: 10px;
  }
}