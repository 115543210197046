.report-preview-popup {
    display: flex;
    flex-direction: column;
    height: 100%;
    user-select: none;
    
    .report-preview-popup-body {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        iframe {
            height: 100%;
            width: 100%;
            border-radius: 5px;
        }

        .spinner {
            width: 50px;
            height: 50px;

            div {
                border-color: var(--arxs-icon-color) transparent transparent transparent;
            }
        }

        .not.found {
            font-size: 16px;
            color: var(--arxs-secondary-color);
            i {
                padding-right: 5px;
                
            }
        }
    }
    
    .report-preview-popup-actions {
        flex-basis: 40px;
        display: flex;
        flex-direction: row;
    }

    .report-preview-popup-actions {
        flex-basis: 50px;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .report-preview-popup-actions-button {
            flex: 1 1;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.confirm {
                background-color: var(--arxs-status-completed-color);
                color: white;
            }
        }
    }

    .report-preview-popup-nav {
        flex-basis: 40px;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .report-preview-popup-nav-section {
            background-color: var(--arxs-list-background-color);

            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-weight: 600;

            .fa-check {
                color: var(--arxs-status-completed-color);
            }

            .fa-question {
                color: var(--arxs-tag-label-selected-color-background);
            }
        }

        .report-preview-popup-nav-button {
            background-color: var(--arxs-list-background-color);

            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            cursor: pointer;

            &:hover {
                background-color: var(--arxs-list-card-background-color);
            }

            &.inactive {
                cursor: not-allowed;

                &:hover {
                    background-color: var(--arxs-list-background-color);
                }
            }
        }
    }
}