.scheduler {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  gap: var(--arxs-field-gap);

  .radio-group {
    .radio-group-option {
      input[type="radio"] {
        // margin-top: 3px;
      }

      .row {}
    }
  }

  .field-spacer {
    margin-top: 7px;
  }
}