.form {
    .form-body {
        .sections {
            position: relative;

            .form-actions {
                position: absolute;
                display: flex;
                padding: 5px;
                gap: 10px;
                top: 10px;
                right: 50px;
                z-index: 100;
                user-select: none;
                gap: 10px;
                align-self: flex-end;
                color: var(--arxs-secondary-color);

                @media only screen and (max-width: 500px) {
                    top: 8px;
                    right: 40px;
                }
    
                .form-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background-color: var(--arxs-secondary-color);
            
                    i {
                        font-size: 20px;
                        color: #fff;
                    }
    
                    &.all-ok, &:hover {
                        border: 1px solid var(--arxs-create-color);
                        background: linear-gradient(180deg, var(--arxs-create-color-gradient-lo), var(--arxs-create-color-gradient-hi));
                    }
                }
            }
        }
    }
}

