.image-overview {
    width: 100%;

    .Collapsible {
        .Collapsible__trigger {
            display: block;
            position: relative;
            padding: 20px;

            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            color: var(--arxs-grey-base);

            cursor: pointer;

            border-bottom: 1px solid var(--arxs-nav-border-color);
        }

        .Collapsible__trigger::after {
            position: absolute;
            right: 20px;

            font-family: "Font Awesome 6 Pro";
            font-size: 10px;
            font-weight: 400;
            content: "\f078";

            color: var(--arxs-primary-inactive-color);
        }

        .Collapsible__trigger:hover::after {
            color: var(--arxs-primary-color);
        }

        .Collapsible__contentOuter {
            width: 100%;

            .Collapsible__contentInner {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 0 10px 10px 0px;
                overflow-x: auto;

                .image-content {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    height: 202px;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    gap: 28px;                    

                    .image:first-child {
                        .preview {
                            width: 202px;
                            height: 202px;
                        }
                    }
    
                    .image:not(:first-child) {
                        flex-basis: 87px;
                        max-width: 113px;
                        .preview {
                            width: 87px;
                            height: 87px;
                        }
                    }

                    .image {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;

                        .preview {
                            cursor: move;
                            border-radius: 15px;

                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            justify-content: flex-end;
                        }

                        .preview.readOnly {
                            cursor: pointer;                      
                        }

                        .preview.empty {
                            cursor: pointer;
                            display: flex;
                            border: 2px dashed #acbcd9;
                            background-color: var(--arxs-border-shadow-color);
                            color: var(--arxs-secondary-color);
                            align-items: center;
                            justify-content: center;

                            i.fa-plus {
                                font-size: 20px;
                            }
                        }

                        .preview.empty.readonly {
                            cursor: default;
                            border: none;
                        }

                        .image-actions {
                            display: flex;
                            flex-direction: column;
                            padding-right: 5px;
                            padding-left: 5px;
                            padding-top: 5px;

                            .image-remove {
                                padding-left: 2px;
                                cursor: pointer;
                                margin-bottom: 5px;

                                i {
                                    font-size: 16px;
                                    color: var(--arxs-secondary-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .image-content-oneline {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
        margin-top: 20px;
        gap: 28px;        

        .image {
            flex-basis: 87px;
            max-width: 113px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            max-height: 87px;
            
            .preview {
                width: 87px;
                height: 87px;
                cursor: move;
                border-radius: 15px;

                display: flex;
                flex-direction: co;
                align-items: flex-end;
                justify-content: flex-end;
            }

            .preview.readOnly {
                cursor: pointer;                      
            }

            .preview.empty {
                cursor: pointer;
                display: flex;
                border: 2px dashed #acbcd9;
                background-color: var(--arxs-border-shadow-color);
                color: var(--arxs-secondary-color);
                align-items: center;
                justify-content: center;

                i.fa-plus {
                    font-size: 20px;
                }
            }

            .preview.empty.readonly {
                cursor: default;
                border: none;
            }

            .image-actions {
                display: flex;
                flex-direction: column;
                padding-right: 5px;
                padding-left: 5px;
                padding-top: 5px;

                .image-remove {
                    padding-left: 2px;
                    cursor: pointer;
                    margin-bottom: 5px;

                    i {
                        font-size: 16px;
                        color: var(--arxs-secondary-color);
                    }
                }
            }
        }
    }
}
