.notification-tiles {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-direction: row;
  gap: 20px;
}

@media only screen and (max-width: 1223px) {
  .notification-tiles {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  }
}