.risk-value-selection-popup {
  background-color: var(--arxs-nav-background-color);
  box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
  border-radius: 5px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 320px;

  h1 {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }

  .risk-value-selection-popup-close {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }

  .risk-value-selection-popup-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .buttons {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      position: sticky;
      bottom: 10px;

      .button {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}


@media only screen and (max-height: 800px) {
  .risk-value-selection-popup {
    padding: 10px;
  }
}