.form-item-editor-row {
    .form-item-editor-control {
        .form-item-control-container {
            .ce-form-lookup {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 5px;
                align-items: flex-start;
                grid-gap: 5px;

                .ce-form-lookup-type {
                    width: 100%;
                }

                .ce-form-lookup-container {
                    width: 100%;

                    .ce-form-lookup-line {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        align-items: center;

                        .ce-form-lookup-value {
                            width: 100%;
                        }

                        .ce-form-lookup-actions {
                            display: flex;
                            flex-direction: row;
                            min-width: 20px;

                            .ce-form-lookup-action {
                                margin-top: 5px;
                                cursor: pointer;
                                color: var(--arxs-icon-inactive-color);

                                &:hover {
                                    color: var(--arxs-icon-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}