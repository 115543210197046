.information-tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 20px 20px;

  .field {
    width: 100% !important;
  }

  .field-container-single-lines {
    padding-top: 10px;
    width: 100%;

    .separator {
      margin-bottom: 10px;
    }

    .field {
      display: flex;
      position: relative;
      flex-direction: row;
      align-content: center;
      justify-content: space-evenly;
      width: 100% !important;
      padding: 2px 5px 0 0 !important;

      .label {
        width: 100%;
      }

      .value {
        width: 100%;
      }
    }
  }
}