.library-import-progress {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 150px);
    width: 300px;
    height: 30px;

    .k-selected {
        border-color: var(--arxs-border-shadow-color);
        color: white;
        background-color: var(--arxs-icon-color);
        margin-top: -30px;
        height: 30px !important;
    }
}