.loading {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    .splash {
        
        height: 100%;
        width: 100%;
        color: var(--arxs-login-splash-color, #fff);
        background: var(--arxs-login-splash-background, linear-gradient(180deg, #4D79FF 0%, #5045E6 100%));
        box-shadow: 0 2px 4px 0 var(--arxs-border-shadow-color, rgba(0,27,77,.1));
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 

        position: relative;

        .logo {
            font-size: 7px;
            letter-spacing: 3.69px;
            line-height: 17px;
            font-family: var(--arxs-font-condensed, 'Roboto Condensed', sans-serif);
            text-align: center;

            h1 {
                display: inline-block;
                font-weight: normal;
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
                height: 24px;
                margin-right: 15px;
            }
        }

        .splash-content {
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .motto {
                text-align: center;

                h2 {
                    display: inline-block;
                    width: 380px;
                    color: #fff;
                    font-size: 30px;
                    line-height: 33px;
                    font-weight: normal;
                }
            }

            .device {
                margin-top: 37px;
                display: flex;
                justify-content: center;

                @media only screen and (max-width: 1223px) {
                    img {
                        width: 120%;
                    }
                }
            }
        }
    }
}