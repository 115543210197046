.badge {
    display: inline-block;

    background-color: var(--arxs-badge-background-color);

    border-radius: 7px;
    height: 14px;
    padding: 2px 5px 2px 5px;

    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    text-align: center;
    vertical-align: middle;

    &.red {
        color: #fff;
        background-color: var(--arxs-badge-accent-background-color);
    }

    &.blue {
        color: #ffff;
        background-color: var(--arxs-icon-color);
    }

    &.pink {
        color: #ff6b6a;
        background-color: #ffd4d4;
    }
}
