.mail-popup {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > .row {
    width: 100%;
    height: 100%;
    gap: 10px;

    display: flex;
    flex-direction: column;

    > .field:nth-child(4) {
      > div {
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: var(--arxs-input-background-color);
        border: 1px solid var(--arxs-input-border-color);
        padding: 0 5px;
        height: 29px;
      }
    }

    > .field.full-width {
      flex-grow: 1;
      justify-content: start;
      overflow: hidden;

      > .k-editor {
        height: 100%;
      }

      .field-ellipsis {
        height: 100%;

        .markdown {
          background-color: var(--arxs-input-background-color);
          border: 1px solid var(--arxs-input-border-color);
          padding: 5px 5px;
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  .mail-popup-buttons {
    position: absolute;
    right: 25px;
    bottom: 25px;
    display: flex;
    justify-content: flex-end;

    .mail-popup-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-left: 15px;
      border-radius: 50%;
      i {
        font-size: 20px;
      }
    }

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
}
