.geo-lookup {
  display: flex;
  flex-direction: column;
  height: 100%;

  .geo-lookup-toolbar {
    flex: 0 0 30px;
    display: flex;
    background-color: var(--arxs-input-background-color);
    border-bottom: 1px solid var(--arxs-border-shadow-color);
  }

  .geo-lookup-content {
    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    background-color: var(--arxs-list-background-color);
    overflow: hidden;

    .geo-lookup-mapcontainer {
      flex-grow: 1;
      height: 100%;
      position: relative;

      #map {
        width: 100%;
        height: 100%;
      }

      .geo-lookup-closest-address {
        position: absolute;
        border-radius: 5px;
        padding: 20px;
        left: 5px;
        bottom: 5px;
        background-color: var(--arxs-list-background-color);
        color: var(--arxs-primary-color);

        h3:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

    .layertree {
      padding: 20px;
      flex-basis: 200px;
      border-radius: 0;
      background-color: var(--arxs-background2-color);

      .ant-tree-node-content-wrapper.ant-tree-node-selected,
      .ant-tree-node-content-wrapper:hover {
        background-color: var(--arxs-background-color);
      }

      .ant-tree-switcher {
        display: none;
      }
    }
  }

  .geo-lookup-selected {
    background-color: var(--arxs-icon-color);
    color: #fff;
    width: 100%;
    flex: 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin-left: 5px;
    }

    cursor: pointer;
  }
}

.ant-select-dropdown {
  background-color: var(--arxs-list-background-color);
  color: var(--arxs-primary-color);
}

.ol-zoom {
  width: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 5px;

  button {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    background: var(--arxs-icon-color);
    border-color: var(--arxs-icon-color);
    color: #fff;
    border-radius: 4px;
    border: 1px solid #99bcff33;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
      box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
    }

    i {
      font-size: 16px;
    }
  }
}
