.form-control {
    .form-control-field {
        .prerecommendation {
            display: flex;
            flex-direction: row;
            gap: 5px;

            .prerecommendation-values {
                width: 100%;

                ul {
                    list-style-type: disc;
                    padding-left: 12px;

                    textarea {
                        min-height: 32px;
                    }
                }
            }
        }
    }
}