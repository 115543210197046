.form-control {
    .form-control-field {
        .risk-measurements {
            display: flex;
            flex-direction: column;

            ul {
                list-style-type: disc;
                padding-left: 12px;
            }

            .risk-measurement {
                display: flex;
                flex-direction: column;

                gap: 5px;
                padding-bottom: 5px;

                .risk-measurement-header {
                    display: flex;
                    flex-direction: column;

                    .risk-measurement-header-line {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        padding-bottom: 5px;

                        .risk-measurement-header-line-values {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            align-items: center;

                            label {
                                min-width: 50px;
                            }
                        }
                    }

                    textarea {
                        min-height: 32px;
                    }
                }

                .risk-measurement-value {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                    .risk-measurement-value-header {
                        display: flex;
                        flex-direction: column;
                    }

                    .risk-measurement-value-value {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        width: 100%;
                        gap: 5px;
                    }

                    textarea {
                        min-height: 32px;
                    }

                    i {
                        font-size: 24px;
                    }

                    i.fas.fa-thumbs-up.status-active {
                        rotate: -45deg;
                    }

                    i.fas.fa-thumbs-up.status-warning {
                        rotate: -90deg;
                    }

                    i.fas.fa-thumbs-up.status-severe {
                        rotate: -135deg;
                    }

                    i.fas.fa-thumbs-up.status-error {
                        rotate: 180deg;
                    }

                    .riskAnalysis-action {
                        size: 16px;
                        margin-top: 5px;
                        cursor: pointer;
                        color: var(--arxs-icon-inactive-color);

                        &:hover {
                            color: var(--arxs-icon-color);
                        }

                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}