.tree-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  .tree-nav-container {
    min-width: 340px;
    height: 100%;
    display: flex;
    overflow: hidden;
    padding-right: 10px;

    .tree-nav {
      flex-grow: 1;
      overflow: auto;
    }
  }

  .tree-card-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: var(--arxs-list-background-color);
    border-radius: 5px;

    .card-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 10px 15px;
      gap: 20px;

      .list-card-wrapper {
        flex: 0 0 280px;
        width: 280px;
        margin: 0;
      }
    }
  }
}
