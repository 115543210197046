.folder-button {
  position: relative;
  font-size: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: solid 1px var(--arxs-grid-border-color);
  border-top: solid 1px var(--arxs-grid-border-color);
  padding: 6px 5px 8px 10px;
  cursor: pointer;
  margin-right: 5px;
  background: var(--arxs-grid-alt-background);
  z-index: 1;

  &::before {
    position: absolute;
    top: 3px;
    right: -9px;
    width: 20px;
    height: 35px;
    content: " ";
    border-right: solid 1px var(--arxs-grid-border-color);
    transform: rotate(-30deg);
    background: var(--arxs-grid-alt-background);
    z-index: -1;
  }

  .badge {
    margin-left: 5px;
  }

  &.active {
    background: var(--arxs-grid-background);
    z-index: 2;
    &::before {
      background: var(--arxs-grid-background);
      z-index: -1;
    }
  }
}