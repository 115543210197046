.wizard-navigator {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  flex-basis: 225px;

  margin-left: 10px;
  margin-right: 40px;
  padding: 30px;
  top: 0px;

  color: var(--arxs-wizard-label-color);
  background-color: var(--arxs-wizard-background);
  border-radius: 10px;
  border: 1px solid var(--arxs-wizard-border-color);

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 40px;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      
      .title {
        flex: 1 1;
        display: flex;
        margin-left: 15px;
        font-size: 14px;
      }

      &.active {
        .circle {
          color: #fff;
          border: 1px solid var(--arxs-icon-color);
          background-color: var(--arxs-icon-color);
        }

        .title {
          font-weight: 900;
          color: var(--arxs-primary-color);
        }
      }
    }
  }
  .action {
    margin: 30px -30px -30px -30px;
    padding: 10px;
    color: #fff;
    border-radius: 0 0 10px 10px;
    text-align: center;
    border: 1px solid var(--arxs-icon-color);
    background-color: var(--arxs-icon-color);
    cursor: pointer;
  }
}