.checklist {
  width: 100%;

  .Collapsible__trigger {
    font-size: 16px;
    padding: 10px;
    font-weight: 600;
    display: block;
    border: 1px solid var(--arxs-border-shadow-color);

    .checklist-header-actions {
      position: absolute;
      right: 50px;
      top: 13px;
      font-size: 12px;

      display: flex;
      flex-direction: row;
      gap: 5px;

      > div {
        cursor: pointer;
        color: var(--arxs-icon-inactive-color);

        &:hover {
          color: var(--arxs-icon-color);
        }
      }
    }
  }

  .checklist-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--arxs-background-color);

    .checklist-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--arxs-input-gap);
      height: 50px;
      width: 100%;
      padding: 0 10px;
      border: 1px solid var(--arxs-border-shadow-color);


      .checkbox {
        input[type=checkbox]:not(.k-checkbox):checked + label:before {
          background: var(--arxs-status-active-color);
        }

        input[type=checkbox]:not(.k-checkbox) + label:before {
          background-color: var(--arxs-input-background-color);
        }
      }

      textarea {
        margin-top: 0 !important;
      }

      .checklist-item-action {
        cursor: pointer;
        color: var(--arxs-icon-inactive-color);

        &:hover {
          color: var(--arxs-icon-color);
        }
      }
    }

    .checklist-action-add {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      padding: 0 10px 0 10px;
      font-weight: 600;
      border: 1px solid var(--arxs-border-shadow-color);
      cursor: pointer;
      color: var(--arxs-icon-color);

      i {
        margin-left: 5px;
      }
    }
  }
}
