.structure-filter {
  height: 100%;
  width: 200px;
  

  .structure-filter-header {
    height: 58px;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    width: 200px;

    i {
      padding-left: 5px;
    }
  }

  .structure-filter-dropdown {
    position: absolute;
    z-index: 99;
    width: 200px;

    flex-direction: column;
  }
}

.k-popup {
  .k-list {
    width: 100%;
    .k-list-item {
      &:hover {
        .dropdown-item-icon {
          color: white;
        }
      }
      align-items: center;
    }
  }
}