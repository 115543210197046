.signature-popup-document-not-available {
    text-align: center;
    
    > i {
        font-size: 24px;
    }

    > div {
        margin-top: 15px;
        width: 300px;
    }
}