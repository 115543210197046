.activity-overview-wrapper {
	height: calc(100% + 30px);
	display: flex;
	margin-left: -30px;
	margin-top: -30px;

	@media only screen and (max-width: 500px) {
		height: calc(100% + 20px);
		margin: -20px -15px 0 -15px;
	}

	.spinner { 
		> div {
			border-color: var(--arxs-icon-color) transparent transparent transparent;
		}
	}

	.activity-overview-object-pane {
		user-select: none;
		white-space: nowrap;
		flex: 0 0 360px;
		text-align: left;
		height: 100%;
		border-right: var(--arxs-nav-border);
		display: flex;
		flex-direction: column;
		position: relative;

		@media only screen and (max-width: 500px) {
			flex: 1 0 100%;
		}

		.activity-overview-object-pane-header {
			flex: 0 0 60px;
			padding: 18px 0 17px 20px;
			background-color: var(--arxs-activity-overview-background-color);
			border-bottom: var(--arxs-nav-border);
			display: flex;
			padding-right: 5px;

			.input-wrapper {
				position: relative;
				border: 1px solid var(--arxs-border-shadow-color);
				border-radius: 5px;
				height: 32px;
				flex-grow: 1;
				padding: 1px;
				margin-left: 5px;

				input {
					width: calc(100% - 20px);
					border: none;
					height: 28px;
				}

				.fa-search {
					position: absolute;
					top: 10px;
					right: 5px;
				}
			}
		}
		.activity-overview-objects-list {
			flex-grow: 1;
			background-color: var(--arxs-list-background-color);

			&.loading {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.activity-overview-activity-container-outer {
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 10px;
				
				.activity-overview-activity-date-notification {
					align-self: flex-start;

					display: flex;
					align-items: center;
					justify-content: center;

					width: 100%;
					overflow: hidden;
					color: var(--arxs-grey-base);
					background-color: var(--arxs-scrollbar-background-color);
					height: 20px;
					font-size: 14px;
				}
				.activity-overview-activity-container-inner {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
		
					.selected {
						border: 1px solid var(--arxs-status-active-color);
						box-shadow: 0 0 10px 0 rgba(77, 121, 255, 0.7);
					}

					.list-card-wrapper {
						width: 80%;
					}

					.bullet-glowing {
						position: absolute;
						right: 16px;
					}
				}
			}
		}
		.activity-overview-mark-as-read {
			background-color: var(--arxs-icon-color);
			color: white;
			width: auto;
			left: 50%;
    		transform: translate(-50%, 0);
			
			display: flex;
			justify-content: center;
			align-items: center;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			cursor: pointer;
			position: absolute;
			bottom: 10px;
			height: 31px;
			border-radius: 5px;
			opacity: 0.7;
			padding: 0 10px;
			i {
				margin-right: 5px;
			}
			
			&:hover {
				opacity: 1;
			}
		}
	}
	.activity-overview-object-activities-pane {
		flex-grow: 1;
		user-select: none;
		//white-space: normal;
		overflow: hidden;
		text-align: left;
		border-right: 1px solid var(--list-header-border-color);
		height: 100%;
		position: relative;

		.activities {
			.activities-body {
				overflow: auto;
			}
		}

		.activity-overview-object-activities-list {
			height: 100%;

			.activities-body {
				background-color: var(--arxs-activity-overview-background-color);
			}
		}

		button.icon {
			position: absolute;
			background-color: var(--arxs-icon-color);
			color: #fff;
			top: 10px;
			right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 40px;
			width: 40px;
			border-radius: 50%;
			border: 1px solid var(--arxs-icon-color);;
		}
	}
}
