.form-control {
    .form-control-field {
        .periodicals {
            display: flex;
            flex-direction: column;
            gap: 5px;
            
            .periodical {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .periodical-identification {
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: flex-start;
                width: 100%;

                .dropdown.periodical-module {
                    flex-basis: 150px;
                }

                textarea {
                    flex-grow: 1;
                    width: auto;
                    min-height: 29px;
                    line-height: 1.5;
                }
            }

            .periodical-details {
                display: flex;
                flex-direction: row;
                gap: 5px;
                padding-bottom: 5px;
                align-items: flex-start;
                width: 100%;
            }

            .dropdown.periodical-executor {
                width: 200px;
                min-width: 200px !important;
            }

            .dropdown.periodical-frequency {
                width: 120px;
                min-width: 120px;
            }

            .periodical-number {
                width: 60px;
            }

            .periodical-actions {
                display: flex;
                flex-direction: row;
                min-width: 20px;

                .periodical-action {
                    margin-top: 5px;
                    cursor: pointer;
                    color: var(--arxs-icon-inactive-color);

                    &:hover {
                        color: var(--arxs-icon-color);
                    }
                }
            }
        }
    }
}