.form-control {
    .form-control-field {
        .ce-form-lookup {
            display: flex;
            flex-direction: column;
            gap: 5px;
            
            .ce-form-lookup-container {
                .ce-form-lookup-line {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    .autocomplete {
                        width: 100%;
                    }
                }
            }
        }
    }
}