.popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--arxs-popup-backdrop-color);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup.maximized {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .popup {
    max-width: 70%;
    max-height: 70%;
    min-width: 490px;
    height: 70%;
    width: 70%;
    background-color: var(--arxs-nav-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;

    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1024px) {
      width: 90%;
      height: 90%;
      min-width: 90vw;
      max-width: 90vw;
      min-height: 90%;
      max-height: 90%;
      padding: 0;
    }

    @media only screen and (max-width: 500px) and (orientation: portrait) {
      width: 100%;
      height: 100%;
      min-width: 100vw;
      max-width: 100vw;
      min-height: 100%;
      max-height: 100%;
    }

    &.fit-content-width {
      width: fit-content;
    }

    &.fit-content-height {
      height: fit-content;
    }

    .popup-header {
      flex: 0 0 56px;
      position: relative;

      h1 {
        position: absolute;
        left: 20px;
        right: 32px;
        top: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
          color: var(--arxs-icon-color);
        }
      }

      .popup-close {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;
      }

      .popup-maximize-minimize {
        position: absolute;
        right: 48px;
        top: 24px;
        cursor: pointer;
      }
    }

    .popup-body {
      flex-grow: 1;
    }
  }
}