.icon-overview {
    width: 100%;

    .icon-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
        margin-top: 20px;
        gap: 28px;

        .icon-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            // flex-basis: 113px;

            .icon-image.preview {
                border-radius: 15px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: flex-end;
            }

            .icon-image.preview.empty {
                cursor: pointer;
                display: flex;
                border: 2px dashed #acbcd9;
                background-color: var(--arxs-border-shadow-color);
                color: var(--arxs-secondary-color);
                align-items: center;
                justify-content: center;
                

                i.fa-plus {
                    font-size: 20px;
                }
            }

            .icon-image.preview.empty.readonly {
                cursor: default;
                border: none;
            }

            .icon-actions {
                display: flex;
                flex-direction: column;
                padding-right: 5px;
                padding-left: 5px;
                padding-top: 5px;

                .icon-remove {
                    padding-left: 2px;
                    cursor: pointer;
                    margin-bottom: 5px;

                    i {
                        font-size: 16px;
                        color: var(--arxs-secondary-color);
                    }
                }
            }
        }
    }
}
