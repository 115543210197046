.multiselect {
    .k-searchbar {
        display: flex;
        align-items: center;
    }

    &.icon {
        background-color: var(--arxs-icon-color);
        border: 1px solid var(--arxs-icon-border-color);
    }
    
    &.disabled {
        opacity: 0.7;
    }

    .k-input-values {
        width: 100%;
    }

    .k-multiselect-wrap {
        input {
            color: var(--arxs-primary-color);

            &::placeholder
                , &::-webkit-input-placeholder
                , &::-moz-placeholder
                , &:-moz-placeholder
                , &:-ms-input-placeholder {
                color: var(--arxs-secondary-color);
            }
        }
    }
}

.popup-auto-width {
    ul {
        > li.k-item {
            padding: 3px 1rem;
            overflow: hidden;
            .dropdown-item-icon { 
                flex: 0 0 30px;
            }
            .dropdown-item-checkbox {
                flex: 0 0 30px;
                margin-bottom: 2px;
            }
            .dropdown-item-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}