.signature {
    width: 230px;
    height: 115px;

    background-color: white;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
