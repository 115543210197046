.form-control {
    .form-control-field {
        .toggle-button {
            display: flex;
            justify-content: space-evenly;

            .toggle {
                flex-grow: 1;
            }

            .toggle.on.ok {
                background-color: var(--arxs-status-completed-color-background) !important;
                border: 1px solid var(--arxs-status-completed-color-background);
                color: var(--arxs-status-completed-color);
            }

            .toggle.on.nok {
                background-color: var(--arxs-status-error-color-background) !important;
                border: 1px solid var(--arxs-status-error-color-background);
                color: var(--arxs-status-error-color);
            }

            .toggle.on.neutral {
                background-color: var(--arxs-status-active-color-background) !important;
                border: 1px solid var(--arxs-status-active-color-background);
                color: var(--arxs-status-active-color);
            }
        }
    }
}