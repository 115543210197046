.codeElement-list {
  width: 100%;

  .codeElement-list-content {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: var(--arxs-field-gap);
  }

  .codeElement-list-actions{
    .codeElement-list-action-add {
      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px dashed #acbcd9;
      border-radius: 10px;
      background-color: rgba(89, 121, 179, 0.05);
      height: 40px;

      cursor: pointer;

      color: var(--arxs-secondary-color);
      font-weight: bold;
      font-size: 12px;

      i.fa-plus {
        // font-size: 20px;
        // color: var(--arxs-icon-color);
        margin-left: 10px;
      }
    }
  }
}
