.tag-overview {
  width: 100%;
  
  .tag-overview-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    padding-top: 10px;

    .tag-label {
      flex: 0 0 310px;
      width: 310px;
    }
  }
}
