.import-settings {
  display: flex;
  flex-direction: row;

  .import-settings-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1px;
    gap: 10px;

    .import-settings-actions {
      flex-grow: 1;
      display: flex;
      flex-direction: row;

      .button {
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }

      .import-settings-button {
        width: 42px;
        margin-right: 10px;
        display: flex;
        border-radius: 3px;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }

      .import-settings-button.import {
        color: var(--arxs-status-active-color);
        background-color: var(--arxs-status-active-color-background);
      }

      .import-settings-button.export {
        color: var(--arxs-status-completed-color);
        background-color: var(--arxs-status-completed-color-background);
      }
    }

    .validation-summary {
      background-color: var(--arxs-wizard-validation-background-color);
      border-radius: 10px;
      border: 1px solid var(--arxs-wizard-validation-border-color);
      padding: 18px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}
