.document-label {
  display: flex;
  align-items: center;
  flex-direction: row;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
  width: 100%;
  flex: 0 0 50px;
  height: 60px;
  background-color: var(--arxs-list-card-background-color);
  
  &.archived {
    background: repeating-linear-gradient(315deg, var(--arxs-background-color), var(--arxs-background-color) 5px, var(--arxs-list-background-color) 5px, var(--arxs-list-background-color) 10px);
  }

  svg {
    cursor: pointer;
  }

  .document-file-type {
    flex: 0 0 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--arxs-input-border-color);
    height: 100%;

    .document-file-type-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: var(--arxs-icon-color);

      cursor: pointer;
    }

    .document-file-type-icon.document {
      color: var(--arxs-status-warning-color);
    }

    .document-file-remove {
      display: none;
      border-radius: 50%;
      background-color: lightgrey;
      width: 30px;
      height: 30px;
      font-size: 15px;
    }
  }

  .document-file-name {
    padding: 0 13px;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    cursor: pointer;
    align-items: center;
    font-size: 14px;

    .document-preview {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .document-name-input{
      display: flex;
      flex-grow: 1;
    }

    > a {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .document-label-actions {
    display: flex;
    flex-direction: row;
    padding-right: 5px;

    .document-label-remove {
      padding-left: 2px;
      cursor: pointer;

      i {
        font-size: 16px;
        color: var(--arxs-secondary-color);
      }
    }

    .document-label-rename {
      padding-left: 2px;
      cursor: pointer;

      i {
        font-size: 16px;
        color: var(--arxs-secondary-color);
      }
    }
  }
}
