.geolocation {
  width: 100%;

  .geolocation-content {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .list-card-wrapper {
      flex: 0 0 310px;
      width: 310px;

      .user-card {
        flex: 0 0 310px;

        padding: 10px;

        display: flex;
        align-items: flex-start;
        flex-direction: row;

        color: var(--arxs-secondary-color);
        background-color: var(--arxs-list-card-background-color);

        box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);

        border-radius: 5px;

        min-height: 60px;
        max-height: 60px;

        > i:first-child {
          font-size: 16px;
          margin-right: 10px;
        }

        .selectable {
          &:hover {
            cursor: pointer;
          }
        }

        .user-card-names {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          font-weight: bold;

          .user-card-name {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .user-card-username {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 10px;
          }
        }

        .user-card-actions {
          display: flex;
          gap: 0 5px;
          font-size: 12px;
          height: 100%;
        }

        .user-card-actions {
          i {
            cursor: pointer;
          }
        }
      }
    }
  }
}
