.popup-body {
  overflow: hidden;

  .taglookup {
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: space-between;
    height: 100%;

    .taglookup-cardlist-info {
      display: flex;
      flex-direction: row;
      background-color: var(--arxs-background-color);
      flex: 0 0 46px;
      padding: 10px 12px;
      overflow-x: auto;

      .taglookup-card-uniquenumber {
        border: 1px solid;
        border-radius: 20px;
        border-color: var(--arxs-border-shadow-color);
        background-color: var(--arxs-grid-background);
        padding: 5px 10px;
        margin: 2px;
        white-space: nowrap;
      }
    }

    .taglookup-details {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      height: 100%;

      .taglookup-treeview {
        width: 342px !important;
        background-color: var(--arxs-background-color);
        height: 100%;

        .tagtreeview {
          height: 100%;
        
          .treeview {
            height:100%;
            .kendo-treeview {
              height: calc(100% - 52px);
            }
          }
        }
      }

      @media only screen and (max-width: 500px) {
        .taglookup-treeview {
          width: 100% !important;
        }
      }

      .taglookup-tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        align-content: flex-start;
        padding: 20px 20px 20px 20px;
        width: 100%;
        gap: 10px;

        .tag-label {
          width: 310px;
        }

        .taglookup-empty {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: var(--arxs-secondary-color);

          .taglookup-empty-icon {
            font-size: 32px;
          }

          .taglookup-empty-text {
            font-size: 14px;
          }
        }
      }
    }

    .taglookup-submit {
      flex: 0 0 50px;
      font-size: 12px;
      background-color: var(--arxs-icon-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i.fa-plus {
        padding-left: 5px;
      }
    }
  }
}
