textarea {
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
  font: 400 13px "Roboto", sans-serif;
  border-radius: 3px;
  padding: 4px;
  border: 1px solid var(--arxs-input-border-color);
  min-height: 64px;
  resize: vertical;

  &:not([readonly]) {
    &.editing,
    &:focus {
      box-shadow: inset 0 0 0 2px var(--arxs-input-border-color);
    }
  }

  &[readonly] {
    cursor: inherit;
    background-color: inherit;
  }

  &.input {
    height: 29px;
    min-height: 24px;
    resize: none;
  }

  &.strikethrough {
    text-decoration: line-through;
  }
}

.textarea {
  display: block;
  overflow: auto;
  width: 100%;
}
