.report-definition-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .Collapsible__trigger {
    font-size: 16px;
    padding: 10px;
    font-weight: 600;
    display: block;
    border: 1px solid var(--arxs-border-shadow-color);

    i.fa-check-circle {
      padding-left: 10px;
    }

    .report-definition-details-sort-actions {
      position: absolute;
      right: 50px;
      display: flex;
      flex-direction: row;
      div {
        i {
          padding-left: 10px;
        }
      }
    }
  }
}
