.popup-body {
    overflow: hidden;

    .rightsmanagement {
        display: flex;
        position: relative;
        flex-direction: column;
        align-content: space-between;
        height: 100%;
        width: 100%;

        .rightsmanagement-details {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 100%;
            align-items: center;

            padding-left: 20px;
            padding-right: 20px;
            width: 100%;

            .spinner-container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .rightsmanagement-details-moduleentry {
                display: flex;
                flex-direction: row;
                padding-top: 20px;

                .rightsmanagement-detials-moduleentry-module {
                    display: flex;
                    flex-direction: row;

                    align-items: center;

                    i {
                        font-size: 16px;
                        width: 18px;
                        color: var(--arxs-secondary-color);
                    }

                    .rightsManagement-details-moduleentry-module-name {
                        font-size: 14px;
                        line-height: 19px;
                        padding-left: 10px;
                        width: 250px;
                    }
                }
                .rightsmanagement-details-modulenetry-rights {
                    .dropdown {
                        width: 200px;
                    }
                }
            }
        }

        .rightsmanagement-submit {
            flex: 0 0 50px;
            font-size: 12px;
            background-color: var(--arxs-icon-color);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            i.fa-plus {
                padding-left: 5px;
            }
        }
    }
}
@media only screen and (max-width: 1224px) {
    .popup-body {
        .rightsmanagement {
            .rightsmanagement-details {
                .rightsmanagement-details-moduleentry {
                    flex-direction: column;
                }
            }
        }
    }
}
