.toggle-button {
  display: flex;
  user-select: none;

  &.disabled {
    opacity: 0.5;
  }

  .toggle {
    border-radius: 4px;
  }
  .toggle ~ .toggle {
    margin-left: 4px;
  }

  .on.priority-low {
    color: var(--arxs-status-completed-color);
    background-color: var(--arxs-status-completed-color-background);
    border-color: var(--arxs-status-completed-color-background);
  }
  .on.priority-normal {
    color: var(--arxs-status-warning-color);
    background-color: var(--arxs-status-warning-color-background);
    border-color: var(--arxs-status-warning-color-background);
  }
  .on.priority-high {
    color: var(--arxs-status-error-color);
    background-color: var(--arxs-status-error-color-background);
    border-color: var(--arxs-status-error-color-background);
  }
}
