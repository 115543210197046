.avatar {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  width: 30px;
  height: 30px;
}

.avatar.contact {
  border-color: var(--arxs-icon-negative-color);
  border-width: 2px;
  border-style: solid;
}
