.activities {
    cursor: pointer;

    li {
        display: block;
        text-decoration: none;
    }

    > li {
        float: left;
        position: relative;
    }
  
    li:hover {
        cursor: pointer;
    }

    .badge {
        display: block;
        position: absolute;
        top: 15px;
        right: 9px;
    }

    .dropdown {
        border: var(--arxs-nav-border);
        border-radius: 0 0 5px 5px;
        background-color: var(--arxs-nav-dropdown-background-color);
        box-shadow: 4px 4px 10px 0 var(--arxs-border-shadow-color);

        position: absolute;
        top: 59px;
        right: 0px;
        width: 310px;
        padding: 0;
        user-select: none;

        z-index: 999;

        transition: all 0.5s ease;
        visibility: hidden;
        opacity: 0;
        display: none;

        .activity-overview {
            color: #4E78FD;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
            padding: 14px 0 17px 0;
        }

        li {
            clear: both;
            width: 100%;

            .dropdown-item {
                padding: 15px;
                display: flex;
                flex-direction: column;
                text-align: left;
                border-bottom: var(--arxs-nav-border);

                &.unread {
                    background-color: var(--arxs-icon-light-background-color);
                    border-left: 3px solid var(--arxs-icon-color);
                }

                .subject {
                    display: flex;
                    margin-bottom: 14px;
                    i {
                        color: var(--arxs-icon-color);
                        font-size: 15px;
                        margin-right: 10px;
                    }
                }
                .author {
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .body {
                    margin-bottom: 5px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-height: 10em;
                    /* This does not work on all browsers but this creates a vertical elipsis  */
                    display: -webkit-box;
                    -webkit-line-clamp: 6;
                    -webkit-box-orient: vertical;  

                    > img {
                        max-height: 10em;
                    }

                    a {
                        img {
                            max-height: 5em;
                        }
                    }
                }

                .ago {
                    margin-top: 5px;
                    color: var(--arxs-secondary-color);
                    i {
                        color: var(--arxs-secondary-color);
                        font-size: 15px;
                        margin-right: 10px;
                    }
                }
                
            }
        }
    }
    &:hover > ul, ul:hover {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    
}