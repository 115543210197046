.details-pane-wrapper {
  .details-pane {
    .details-pane-tabs {
      .react-tabs__tab-panel {
        .form-tab {
          .form-body {
            .sections {
              .Collapsible {
                .Collapsible__contentOuter {
                  .Collapsible__contentInner {
                    padding: 0 5px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
