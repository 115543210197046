.form-control {
    .form-control-field {
        .recommendation {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .conclusion {
                display: flex;
                flex-grow: 1;
                width: 100%;
            }
        }
    }
}