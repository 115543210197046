.icon-lookup {
  display: flex;
  flex-direction: column;
  height: 100%;

  .icon-lookup-toolbar {
    height: 57px;
    display: flex;
    background-color: var(--arxs-input-background-color);
    border-bottom: 1px solid var(--arxs-border-shadow-color);

    .input-wrapper {
      position: relative;
      //border-radius: 5px;
      flex-grow: 1;
      padding: 1px;
      margin-left: 5px;
      border-right: 1px solid var(--arxs-border-shadow-color);

      input {
        width: calc(100% - 43px);
        margin-left: 43px;
        border: none;
        height: 55px;
      }

      &::before {
        position: absolute;
        top: 22px;
        left: 21px;
      }
    }

    .multiselect {
      flex: 0 0 200px;
      height: 57px;

      .k-multiselect-wrap {
        display: flex;
        align-items: center;

        border-top: none;
        border-right: none;
        border-radius: 0;
        border-color: var(--arxs-grid-border-color);

        li {
          color: var(--arxs-primary-color);
          background-color: var(--arxs-input-background-color);
          border: none;
        }

        .k-clear-value {
          display: none;
        }
      }
    }
  }
  .icon-lookup-content-wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;
    background-color: var(--arxs-list-background-color);
    flex-direction: row;
    overflow: hidden;

    .icon-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 10px 15px;
      gap: 10px;
      height: 100%;

      .list-icon-wrapper {
        flex: 0 0 87px;
        width: 87px;
      }
    }
  }
  .icon-lookup-selected {
    background-color: var(--arxs-icon-color);
    color: white;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    i {
      margin-left: 5px;
    }

    cursor: pointer;

    &.disabled {
      background-color: var(--arxs-icon-inactive-color);
      color: var(--arxs-primary-color);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
