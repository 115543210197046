.dropdown {
    .k-searchbar {
        display: flex;
        align-items: center;
    }

    &.icon {
        background-color: var(--arxs-icon-color);
        border: 1px solid var(--arxs-icon-border-color);
    }

    &.disabled {
        opacity: 0.7;
    }

    .k-dropdown-wrap,
    .k-multiselect-wrap {
        input {
            color: var(--arxs-primary-color);

            &::placeholder,
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-moz-placeholder,
            &:-ms-input-placeholder {
                color: var(--arxs-secondary-color);
            }
        }
    }
}

.k-popup {
    min-width: 250px;

    .k-list {
        .k-list-item {
            display: flex;
            gap: 0.6rem;
            padding: 0.2rem 1rem;

            &:hover {
                background-color: var(--arxs-icon-color);
                color: white;
            }

            .dropdown-item-icon {
                flex: 0 0 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--arxs-icon-color);
                font-size: 16px;
                height: 28px;
            }

            .dropdown-item-checkbox {
                flex: 0 0 30px;
            }

            .dropdown-item-title {
                flex: 1 1;

                &.strikethrough {
                    text-decoration: line-through;
                }
            }
        }
    }
}
