.employee-selector-item {
  .avatar {
    flex: 0 0 30px;
  }
  span {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }
}