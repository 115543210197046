html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: var(--arxs-font-family, "Roboto", sans-serif);
  background-color: var(--arxs-background-color);
  color: var(--arxs-primary-color);
  font-size: 12px;
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

a,
u {
  text-decoration: none;
  color: var(--arxs-primary-color);
}

ul {
  list-style-type: none;
}

/*
  This disables zooming of inputs on iOS.
  see https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
*/
@media only screen and (max-width: 1223px) {

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

textarea,
input {
  color: var(--arxs-primary-color);
  background-color: var(--arxs-input-background-color);
}

textarea::placeholder {
  color: var(--arxs-secondary-color);
  font-family: var(--arxs-font-family, "Roboto", sans-serif);
}

.k-numerictextbox:focus-within {
  box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
}

.k-popup {
  overflow-y: auto;
}

.k-datepicker, .k-datetimepicker {
  padding: 0 0 0 0.5rem;
}

.k-input {
  width: 10em;
}

.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-dateinput-wrap,
.k-numeric-wrap,
.k-dropdown-wrap {
  border: var(--arxs-input-border-color) 1px solid !important;
  background-color: var(--arxs-input-background-color) !important;
  color: var(--arxs-primary-color) !important;

  .k-item {
    display: flex;
    flex-direction: row;
    background-color: var(--arxs-input-background-color) !important;
    color: var(--arxs-primary-color) !important;
  }

  .k-state-focused {
    box-shadow: 0 0 0 0.1rem var(--arxs-input-border-color);
  }

  .k-input-values,
  .k-input,
  .k-input.k-focus {
    border: none;
  }

  textarea, input {
    border-color: transparent;
    width: 100%;
  }
}
.k-multiselect,
.k-multiselect-wrap {
  border: var(--arxs-input-border-color) 1px solid !important;
  background-color: var(--arxs-input-background-color) !important;
  color: var(--arxs-primary-color) !important;

  .k-item {
    display: flex;
    flex-direction: row;
    background-color: var(--arxs-input-background-color) !important;
    color: var(--arxs-primary-color) !important;
  }

  .k-input-values {
    border: none;
    overflow: auto;

    .k-chip {
      height: 16px;
      border-radius: 2px;
      padding: 0;

      .k-chip-actions { 
        margin-right: 5px;
      }
    }
  }

  input {
    border-color: transparent;
  }

  ::-webkit-scrollbar {
    height: 8px;
  }
}

.k-autocomplete.k-header,
.k-autocomplete,
.k-autocomplete.k-header:hover,
.k-autocomplete.k-header.k-state-hover,
.k-autocomplete:hover,
.k-autocomplete.k-state-hover {
  background-color: var(--arxs-input-background-color);
  color: var(--arxs-primary-color);
  border: 1px solid var(--arxs-border-shadow-color);
  border-radius: 5px;
  padding: 0 5px 0 5px;

  input,
  input::placeholder {
    border: none;
    width: 100%;
    height: 32px;
    color: var(--arxs-primary-color);
    font-family: var(--arxs-font-family, "Roboto", sans-serif);
    font-size: 12px;
    text-overflow: ellipsis;
  }

  input::placeholder {
    color: var(--arxs-secondary-color);
  }
}

.k-autocomplete.k-header:focus-within,
.k-autocomplete:focus-within {
  border: 1px solid var(--arxs-border-shadow-color);
  border-radius: 5px;
  box-shadow: none !important;
}

.k-autocomplete.k-header.k-state-open,
.k-autocomplete.k-header.k-state-focused,
.k-autocomplete.k-header.k-state-focus,
.k-autocomplete.k-state-open,
.k-autocomplete.k-state-focused,
.k-autocomplete.k-state-focus {
  background-color: var(--arxs-input-background-color);
  border-color: var(--arxs-input-border-color);
  color: var(--arxs-primary-color);
}

.k-datetime-container .k-datetime-wrap {
  width: 30em;

  .k-datetime-calendar-wrap {
    flex: 0 0 30em;
  }

  .k-datetime-time-wrap {
    flex: 0 0 30em;
  }
}

.red {
  color: #ff6666;
}

.flip-horizontally {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.list-card.status-active,
.folder-button.status-active,
.k-event.status-active,
.b-sch-color-blue,
.b-sch-color-blue-gradient {
  border-left: 5px solid var(--arxs-status-active-color);
}

.list-card.status-warning,
.folder-button.status-warning,
.k-event.status-warning,
.b-sch-color-orange,
.b-sch-color-orange-gradient {
  border-left: 5px solid var(--arxs-status-warning-color);
}

.list-card.status-error,
.folder-button.status-error,
.k-event.status-error,
.b-sch-color-red,
.b-sch-color-red-gradient {
  border-left: 5px solid var(--arxs-status-error-color);
}

.list-card.status-completed,
.folder-button.status-completed,
.k-event.status-completed,
.b-sch-color-green,
.b-sch-color-green-gradient {
  border-left: 5px solid var(--arxs-status-completed-color);
}

.list-card.status-closed,
.folder-button.status-closed,
.k-event.status-closed {
  border-left: 5px solid var(--arxs-status-closed-color);
}

.list-card.archived
  , .condensed-card.archived > .user-card
  , .mini-card.archived {
  background: repeating-linear-gradient(315deg, var(--arxs-background-color), var(--arxs-background-color) 5px, var(--arxs-list-background-color) 5px, var(--arxs-list-background-color) 10px);
}

.status-active {
  color: var(--arxs-status-active-color);
}

.status-warning {
  color: var(--arxs-status-warning-color);
}

.status-severe {
  color: var(--arxs-status-severe-color);
}

.status-error {
  color: var(--arxs-status-error-color);
}

.status-completed {
  color: var(--arxs-status-completed-color);
}

.status-closed {
  color: var(--arxs-status-closed-color);
}

.badge.status-active {
  color: var(--arxs-status-active-color);
  background-color: var(--arxs-status-active-color-background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
}

.badge.status-warning {
  color: var(--arxs-status-warning-color);
  background-color: var(--arxs-status-warning-color-background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
}

.badge.status-severe {
  color: var(--arxs-status-severe-color);
  background-color: var(--arxs-status-severe-color-background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
}

.badge.status-error {
  color: var(--arxs-status-error-color);
  background-color: var(--arxs-status-error-color-background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
}

.badge.status-completed {
  color: var(--arxs-status-completed-color);
  background-color: var(--arxs-status-completed-color-background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
}

.badge.status-closed {
  color: var(--arxs-status-closed-color);
  background-color: var(--arxs-status-closed-color-background);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
}

.badge.priority-low {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
  color: var(--arxs-status-completed-color);
  background-color: var(--arxs-status-completed-color-background);
}

.badge.priority-normal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
  color: var(--arxs-status-warning-color);
  background-color: var(--arxs-status-warning-color-background);
}

.badge.priority-high {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
  color: var(--arxs-status-error-color);
  background-color: var(--arxs-status-error-color-background);
}

.badge.task-type-internal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
  color: var(--arxs-status-active-color);
  background-color: var(--arxs-status-active-color-background);
}

.badge.task-type-external {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 111px;
  height: 30px;
  color: var(--arxs-status-active-color);
  background-color: var(--arxs-status-active-color-background);
}

.badge.instruction-card-type-safetyinstructioncard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 150px;
  height: 30px;
}

.badge.instruction-card-type-workinstructioncard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  width: 150px;
  height: 30px;
}

.toggle {
  padding: 7px 7px;
  height: 32px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;

  &.on {
    border: 1px solid var(--arxs-icon-color);
    background-color: var(--arxs-icon-color);
  }

  &.off {
    color: var(--arxs-secondary-color);
    border: 1px solid var(--arxs-border-shadow-color);
    background-color: var(--arxs-border-shadow-color);
  }

  i {
    font-size: 16px;
  }
}

.bullet-glowing {
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: var(--arxs-icon-color);
  box-shadow: 0 0 8px 0 rgba(77, 121, 254, 0.7);
}

.k-combobox .k-dropdown-wrap {
  border-color: var(--arxs-input-border-color);

  .k-input {
    color: var(--arxs-primary-color);
    background-color: var(--arxs-input-background-color);
  }
}

.k-animation-container {
  border-color: var(--arxs-grid-border-color);
  color: var(--arxs-grid-color);
  background: var(--arxs-grid-background);

  .k-popup {
    border-color: var(--arxs-grid-border-color);
    color: var(--arxs-grid-header-color);
    background: var(--arxs-grid-header-background);
  }
}

h1 {
  font-size: 22px;
}

th.center,
td.center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.fas, .far {
  font-family: "Font Awesome 6 Pro" !important;
}

.markdown {
  a {
    color: var(--arxs-icon-color);
    text-decoration: underline;
  }
}

label.k-checkbox-label[for="k-editor-link-target"] {
  display: none; 
}