.attachments {
  display: flex;
  align-items: center;
  background-color: var(--arxs-input-background-color);
  border: 1px solid var(--arxs-input-border-color);
  gap: 5px;
  padding: 0 5px;
  position: relative;
  height: 29px;

  .attachment {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 15px;
    color: #fff;
    background-color: var(--arxs-icon-color);
    padding: 5px 10px;

    &:not(.readonly) {
      .name:hover {
        cursor: initial;
      }
    }

    .name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }
    }

    i.fa-times {
      cursor: pointer;
    }
  }

  button {
    padding: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .attachments-popup {
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    width: 250px;
    max-height: 30vh;
    background-color: var(--arxs-nav-background-color);
    box-shadow: 0 2px 10px 0 var(--arxs-border-shadow-color);
    border-radius: 5px;
    overflow: hidden;

    .attachments-popup-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 100%;
      overflow-y: auto;
      margin-top: 35px;
      padding: 0px 10px 10px 10px;

      .attachments-popup-item {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 200px;
        width: 100%;
        gap: 5px;
        color: var(--arxs-primary-color);
        background-color: var(--arxs-input-background-color);
        border-radius: 5px;
        padding: 10px 10px;
        cursor: pointer;

        i {
          font-size: 14px;
          width: 15px;
        }

        .attachments-popup-item-name {
          flex-grow: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:hover {
          background: var(--arxs-icon-color);
          color: #fff;
        }
      }
    }

    > i.fa-times {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
