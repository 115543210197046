.popup-body {
  overflow: hidden;

  .codeElementlookup {
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: space-between;
    height: 100%;

    .codeElementlookup-cardlist-info {
      display: flex;
      flex-direction: row;
      background-color: var(--arxs-background-color);
      flex: 0 0 46px;
      padding: 10px 12px;
      overflow-x: auto;

      .codeElementlookup-card-uniquenumber {
        border: 1px solid;
        border-radius: 20px;
        border-color: var(--arxs-border-shadow-color);
        background-color: var(--arxs-grid-background);
        padding: 5px 10px;
        margin: 2px;
        white-space: nowrap;
      }
    }

    .codeElementlookup-details {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      height: 100%;

      .codeElementlookup-treeview {
        width: 342px !important;
        background-color: var(--arxs-background-color);
        height: 100%;

        .codeelementlookuptreeview {
          height: 100%;

          .treeview {
            .kendo-treeview {
              height: calc(100% - 52px);
              .k-treeview {
                height: 100%;
                overflow-y: auto;
                .k-treeview-lines {
                  .k-treeview-item {
                    .k-treeview-mid {
                      .k-treeview-leaf {
                        &:hover {
                          background-color: var(--arxs-icon-background-color);
                        }

                        i.fas.fa-tag {
                          color: var(--arxs-status-warning-color);
                        }
                      }
                    }
                    .k-animation-container.k-animation-container-relative {
                      .k-child-animation-container {
                        .k-content {
                          .k-treeview-item {
                            .k-treeview-mid {
                              .k-treeview-leaf {
                                &:hover {
                                  background-color: var(--arxs-icon-background-color);
                                }
                                i.fas.fa-tag {
                                  color: var(--arxs-status-warning-color);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      @media only screen and (max-width: 500px) {
        .codeElementlookup-treeview {
          width: 100% !important;
        }
      }

      .codeElementlookup-labels {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        align-content: flex-start;
        padding: 20px 20px 20px 20px;
        width: 100%;
        gap: 10px;

        .codeElement-label {
          width: 310px;
        }

        .codeElementlookup-empty {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: var(--arxs-secondary-color);

          .codeElementlookup-empty-icon {
            font-size: 32px;
          }

          .codeElementlookup-empty-text {
            font-size: 14px;
          }
        }

        .parent-and-children {
          display: flex;
          flex-direction: column;

          .children {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: auto;
            align-content: flex-start;
            padding: 20px 20px 20px 0;
            width: 100%;
            gap: 10px;

            .codeElement-label {
              width: 310px;
            }

            .codeElementlookup-empty {
              display: flex;
              width: 100%;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              color: var(--arxs-secondary-color);

              .codeElementlookup-empty-icon {
                font-size: 32px;
              }

              .codeElementlookup-empty-text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .codeElementlookup-submit {
      flex: 0 0 50px;
      font-size: 12px;
      background-color: var(--arxs-icon-color);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i.fa-plus {
        padding-left: 5px;
      }
    }
  }
}
