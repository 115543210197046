.dzu-dropzone {
  overflow: auto !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .upload-dropzone-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 450px;
    max-width: 450px;
    width: 100%;
    max-height: 200px;
    padding: 10px 20px;

    .upload-input {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed #acbcd9;
      border-radius: 15px;
      background-color: rgba(89, 121, 179, 0.05);
      margin: 10px;
      cursor: pointer;
      flex: 0 0 50px;
      width: 100%;
    }

    .upload-input-empty {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }

    .upload-preview-container {
      display: flex;

      .upload-preview {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        border-radius: 15px;
        width: 87px;
        height: 87px;
        -webkit-flex-direction: column;
        flex-direction: column;
        background-color: #001233b0;
        position: relative;
        margin-left: 10px;
        margin-top: 10px;
      }

      .upload-preview-actions {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 15px;

        .upload-preview-remove {
          padding-left: 2px;
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }
  }
}
