.date-range {
  width: 100%;

  .date-range-content {
    .date-range-content-values {
      padding-top: 10px;
      .date-range-content-values-dates {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .k-daterangepicker {
          .k-daterangepicker-wrap {
            .k-textbox-container {
              padding-top: 0px;
              label.k-label {
                display: none;
                height: 0px;
              }
            }
          }
        }
      }

      .date-range-content-values-times {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        gap: 3px;
        .k-widget.k-timepicker {
          max-width: 149px;
        }
      }
    }
  }
}
