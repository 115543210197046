.document-overview {
    width: 100%;

    .Collapsible {
        .Collapsible__trigger {
            display: block;
            position: relative;
            padding: 20px;

            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            color: var(--arxs-grey-base);

            cursor: pointer;

            border-bottom: 1px solid var(--arxs-nav-border-color);
        }

        .Collapsible__trigger::after {
            position: absolute;
            right: 20px;

            font-family: "Font Awesome 6 Pro";
            font-size: 10px;
            font-weight: 400;
            content: "\f078";

            color: var(--arxs-primary-inactive-color);
        }

        .Collapsible__trigger:hover::after {
            color: var(--arxs-primary-color);
        }

        .Collapsible__contentOuter {
            width: 100%;

            .Collapsible__contentInner {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 0 20px 20px 20px;

                .document_type {
                    width: 100%;

                    .document-content {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: 20px;
                        margin-top: 20px;

                        .document-label {
                            flex-grow: 1;
                            //min-width: 310px;
                            min-width:100%;
                            max-width: 310px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                        }
                    }

                    .document-actions {
                        display: flex;
                        flex-direction: row;
                        height: 42px;

                        .action {
                            width: 42px;
                            margin-right: 10px;
                            display: flex;
                            cursor: pointer;
                            border-radius: 3px;
                            background-color: var(--arxs-border-shadow-color);
                            color: var(--arxs-secondary-color);
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                        }
                    }
                }

                .document-overview-read-only-message {
                    display: block;
                    font-size: 14px;
                    color: var(--arxs-status-warning-color) !important;
                    .fa-exclamation-triangle {
                      margin-right: 5px;
                    }
                }
            }
        }
    }


}
