.pop-over {
    display: flex;
    flex-direction: row;
    gap: 5px;

    background: var(--arxs-background-color);
    color: var(--arxs-primary-color);
    border-radius: 5px;
    padding: 5px;

    align-items: center;

    .pop-over-toggle {
        font-size: 18px;
    }

    .pop-over-panel {
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        visibility: hidden;
        border-right: 2px solid var(--arxs-primary-color);
        padding-right: 5px;
    }

    .pop-over-panel.expanded {
        visibility: visible;
    }
}