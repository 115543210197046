.list-card-wrapper .list-card .list-card-right .list-card-header > div {
    &.must-sign {
        color: var(--arxs-status-warning-color);
        background-color: var(--arxs-status-warning-color-background);
    }

    &.has-signed {
        color: var(--arxs-status-completed-color);   
        background-color: var(--arxs-status-completed-color-background);         
    }
}