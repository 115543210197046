.menu-search {
  display: flex;
  position: relative;
  width: 100%;

  > i {
    position: absolute;
    left: 10px;
    top: 18px;
    font-size: 22px;
  }

  > input {
    height: 60px;
    width: 100%;
    padding-left: 40px;
    line-height: 60px;
    font-size: 22px;

    border: 1px solid var(--arxs-border-shadow-color);
    border-radius: 5px;
  }

  > button {
    height: 60px;
    width: 60px;
    border-radius: 0;
    > i {
      font-size: 22px;
    }
  }
}
